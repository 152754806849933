import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import { NOTIFY } from "../based/Constants";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import { RequestDomain } from "../based/RequestDomain";

function QRModal({ show, onClose, orderId, finallyPrice }) {
    const [busy, setBusy] = useState(false);
    const [partialCod, setPartialCod] = useState(0);

    useEffect(() => {
        if (show) {
            //dựa trên finally price tính ra số tiền cần cọc
            var partialCod = Common.calculateCODToTransfer(finallyPrice);
            setPartialCod(partialCod);
        }
    }, [show, finallyPrice]);

    const CreateQR = async () => {
        if (partialCod === 0) {
            Notify(NOTIFY.ERROR, "Lỗi", "Số tiền hiện tại đang bằng 0");
            return;
        }
        window.open(`${RequestDomain.APIUrl()}/api/Orders/create-qr-share?orderId=${orderId}&transferAmount=${partialCod}&token=${Common.GetToken()}`, "_blank");
    };

    return (
        <CommonModal
            show={show}
            isBusy={busy}
            busyMsg={"Đang tải"}
            title={`Tạo mã Manual QR`}
            onClose={() => onClose()}
            size="md"
            onSave={() => {
                CreateQR();
            }}
        >
            <Wrapper>
                <div className="form-group">
                    <label htmlFor="" className="form-label required">
                        Số tiền cọc/thanh toán
                    </label>
                    <input
                        type="number"
                        value={partialCod}
                        className="form-control"
                        onChange={(e) => {
                            setPartialCod(+e.target.value);
                        }}
                    />
                </div>
            </Wrapper>
        </CommonModal>
    );
}

export default QRModal;

const Wrapper = styled.div``;
