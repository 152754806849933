import React, { useEffect, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import { PLATFORM } from "../based/Constants";
import LazadaConfig from "./Lazada/LazadaConfig";
import ShopeeConfig from "./Shopee/ShopeeConfig";
import TiktokConfig from "./Tiktok/TiktokConfig";
import VNPost from "./VNPost/VNPost";
import GrabExpress4H from "./GrabExpress4H";
import GrabExpress2H from "./GrabExpress2H";
import PancakeConfig from "./Pancake/PancakeConfig";
const PLATFORM_TAB = ["Lazada", "Shopee", "Tiktok", "VNPost", "Grab4H", "Grab2H", "Pancake"];

export default function ConnectPlatformConfig(props) {
    const [selectedPlatform, setSelectedPlatform] = useState(0);

    useEffect(() => {
        let platform = PLATFORM.Undefined;
        if (window.location.href.includes("tiktok_")) platform = PLATFORM.Tiktok;
        else platform = Common.DetectPlatform(props.match.params.platform);
        switch (platform) {
            case PLATFORM.Lazada:
                setSelectedPlatform("Lazada");
                break;
            case PLATFORM.Shopee:
                setSelectedPlatform("Shopee");
                break;
            case PLATFORM.Tiktok:
                setSelectedPlatform("Tiktok");
                break;
            case PLATFORM.Grab4H:
                setSelectedPlatform("Grab4H");
                break;
            case PLATFORM.Grab2H:
                setSelectedPlatform("Grab2H");
                break;
            case PLATFORM.Pancake:
                setSelectedPlatform("Pancake");
                break;
            default:
                setSelectedPlatform("Lazada");
                break;
        }
    }, []);

    function _handleChangeTab(item) {
        setSelectedPlatform(item);
        if (PLATFORM[item] > 0) props.history.push(`/connect-platform-config/${item.toLowerCase()}`);
    }

    function _renderTab() {
        return PLATFORM_TAB.map((item, index) => {
            return (
                <li key={index} className="nav-item">
                    <a
                        className={selectedPlatform == item ? "nav-link active" : "nav-link btn-light"}
                        onClick={async () => {
                            _handleChangeTab(item);
                        }}
                    >
                        {<span className="d-none d-sm-block">{item}</span>}
                    </a>
                </li>
            );
        });
    }

    function _renderBody() {
        switch (selectedPlatform) {
            case "Lazada":
                return <LazadaConfig {...props} />;
            case "Shopee":
                return <ShopeeConfig {...props} />;
            case "Tiktok":
                return <TiktokConfig {...props} />;
            case "VNPost":
                return <VNPost />;
            case "Grab4H":
                return <GrabExpress4H />;
            case "Grab2H":
                return <GrabExpress2H />;
            case "Pancake":
                return <PancakeConfig />;
            default:
                return;
        }
    }

    return (
        <React.Fragment>
            <Breadcrumbs currentPage=" Kết nối kênh bán hàng" />
            <ul className="nav nav-tabs tabs-bordered">{_renderTab()}</ul>
            {_renderBody()}
        </React.Fragment>
    );
}
