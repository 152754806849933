import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Common from "../based/Common";
import { ARTWORK_TYPE, NOTIFY, PAYMENT_METHOD, PAYMENT_SCHEME, PLATFORM, STATUS_TRANSACTION } from "../based/Constants";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import TFUSelect from "../based/inputs/TFUSelect";
import CommonServices from "../based/services/CommonServices";
import OrderServices from "../based/services/OrderServices";
import ReadyListServices from "../based/services/ReadyListServices";
import ReadyToShipProductModal from "./ReadyToShipProductModal";
import Minus from "./assets/Minus.svg";
import Plus from "./assets/Plus.svg";
import Trash from "./assets/Trash.svg";
import Sent from "./assets/Sent.png";
import Textbox from "../based/inputs/Textbox";
import useFormValidate from "../hooks/useFormValidate";
import CampaignServices from "../based/services/CampaignServices";
import ShowImageModal from "./ShowImageModal";
import Reset from "./assets/Reset.png";
import { ConfirmDialog } from "../based/Dialog";
const ReadyObjectType = {
    Design: 0,
};

const initReadyList = {
    id: 0,
    customerName: "",
    facebookId: "",
    totalPrice: 0.0,
    totalSalePrice: 0.0,
    feeShip: 0.0,
    finnallyPrice: 0.0,
    payment: PAYMENT_METHOD.COD,
    status: 0,
    statusName: "",
    discountCampaign: 0,
    discountVoucher: 0,
};

const initReadyListItem = {
    id: 0,
    readyListId: 0,
    objectType: ReadyObjectType.Design,
    objectId: 0,
    appProductVariantId: 0,
    quantity: 0,
    productCost: 0.0,
    printCost: 0.0,
    sellPrice: 0.0,
    salePrice: 0.0,
};

const initReadyListShip = {
    readyListId: 0,
    userName: "",
    provinceCode: "",
    districtCode: "",
    wardCode: "",
    address: "",
    phoneNumber: "",
};

export const initProductDesign = {
    id: 0,
    title: "",
    price: 0,
    salePrice: 0,
    appProductId: 0,
    colorCode: "",
    templateImages: [],
    productOptions: [],
    colorOptions: [],
    listSize: [],
};

const sizeSort = ["S", "M", "L", "XL", "2XL", "3XL", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
const SaleType = {
    FixedPrice: 1,
    Percentage: 2,
};

function ReadyToShipList(props) {
    const id = props.match.params.id;
    const [showModal, setShowModal] = useState(false);
    const [readyListmodel, setReadyListModel] = useState(initReadyList); //thông tin model
    const [readyListShipModel, setReadyListShipModel] = useState(initReadyListShip); //thông tin vận chuyển
    const [listProduct, setListProduct] = useState([{ ...initProductDesign }]); //danh sách sản phẩm
    const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [wards, setWards] = useState([]);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const { addRef, displayError, isValid } = useFormValidate();
    const [megaActive, setMegaActive] = useState(null);
    const [showImageModal, setShowImageModal] = useState(false);
    const [showListImage, setShowListImage] = useState([]);

    useEffect(() => {
        if (id) {
            // call api get ready list
            getDataReady(id);
        } else {
            setReadyListModel({
                payment: PAYMENT_METHOD.COD,
            });
            setListProduct([]);
        }
        getProvinces();
        _getActiveCampaign();
    }, []);

    const _getActiveCampaign = async () => {
        const [err, data] = await CampaignServices.GetCampaignMegaActive();
        if (!err && data) {
            setMegaActive(data);
        } else {
            setMegaActive(null);
        }
    };

    const _handleMegaCampaign = (currentSellPrice) => {
        let megaCampaign = { ...megaActive };
        if (megaCampaign && megaCampaign.campaignMegaDeals) {
            let mcModel = megaCampaign.campaignMegaDeals;
            let megaDeal = mcModel.find((x) => x.minimumOrderValue <= currentSellPrice);
            if (!megaDeal) return [0, ""];
            //nếu sale type là FixedPrice thì giảm theo giá tiền
            let scheme = "";
            if (megaDeal.saleType == SaleType.FixedPrice) {
                scheme = `Giảm ${megaDeal.saleValue} cho đơn hàng từ ${Common.formatCurrency(megaDeal.minimumOrderValue)}`;
                return [megaDeal.saleValue, scheme];
            }
            //nếu sale type là Percentage thì giảm theo phần trăm
            else if (megaDeal.saleType == SaleType.Percentage) {
                let discount = (currentSellPrice * megaDeal.saleValue) / 100;
                discount = Math.floor(discount / 1000) * 1000;
                scheme = `Giảm ${megaDeal.saleValue}% cho đơn hàng từ ${Common.formatCurrency(megaDeal.minimumOrderValue)}`;
                return [discount, scheme];
            }
        }
        return [0, ""];
    };

    async function getDataReady(id) {
        const [err, data] = await ReadyListServices.GetReadyById(id);
        if (!err && data) {
            //thông tin model
            setReadyListModel(data);

            //thông tin vận chuyển
            if (data.readyListShip) {
                setReadyListShipModel(data.readyListShip);

                let provinceCode = data.readyListShip.provinceCode;
                let districtCode = data.readyListShip.districtCode;
                let wardCode = data.readyListShip.wardCode;

                //lấy ra danh sách quận
                getDistricts(provinceCode);

                //lấy ra danh sách phường
                getWards(districtCode);
            }

            //danh sách sản phẩm
            setListProduct(data.groupItems);
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lấy thông tin thất bại");
        }
    }

    async function getProvinces() {
        const [err, data] = await CommonServices.GetProvinces();
        if (!err) {
            let dt = data.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setProvinces(dt);
        } else setProvinces([]);
    }

    async function getDistricts(provinceCode) {
        const [err, data] = await CommonServices.GetDistricts(provinceCode);
        if (!err) {
            let dt = data.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setDistricts(dt);
        } else setDistricts([]);
    }

    async function getWards(districtCode) {
        const [err, data] = await CommonServices.GetWards(districtCode);
        if (!err) {
            let dt = data.map((item) => ({
                label: item.name,
                value: item.id,
            }));

            setWards(dt);
        } else setWards([]);
    }

    const getProductOptions = async (packageType) => {
        const [err, data] = await ReadyListServices.GetReadyProductOptions(packageType);
        if (!err) {
            return data.map((item) => ({
                label: item.name,
                value: item.id,
            }));
        } else return [];
    };

    const getColorOptions = async (appProductId) => {
        const [err, data] = await ReadyListServices.GetReadyProductColorOptions(appProductId);
        if (!err)
            return data.map((item) => ({
                label: item.displayName,
                value: item.name,
            }));
        else return [];
    };

    const getSize = async (model) => {
        const [err, data] = await ReadyListServices.GetReadyProductSizeOptions(model);
        if (!err) {
            let lstS = data.sort((a, b) => sizeSort.indexOf(a.size) - sizeSort.indexOf(b.size));
            return lstS;
        } else return [];
    };

    const handleBuy = async (readyListmodel) => {
        setLoading(true);

        //ds sản phẩm
        let orderCustomizeProducts = [];
        let md = { ...readyListmodel };
        let readyItems = md.readyListItems;
        for (const readyItem of readyItems) {
            let element = {
                appProductVariantId: readyItem.appProductVariantId,
                designId: readyItem.objectId,
                designOrderId: readyItem.designOrderId,
                price: readyItem.sellPrice,
                discount: readyItem.discount,
                salePrice: readyItem.salePrice,
                finallyPrice: readyItem.salePrice,
                quantity: readyItem.quantity,
                printType: readyItem.printType,
            };
            orderCustomizeProducts.push(element);
        }

        //địa chỉ
        let readyShip = readyListmodel.readyListShip;
        let shipAddress = {
            userName: readyShip.userName,
            street: readyShip.address,
            provinceCode: readyShip.provinceCode,
            districtCode: readyShip.districtCode,
            wardCode: readyShip.wardCode,
            phoneNumber: readyShip.phoneNumber,
        };

        //tạo đơn
        let orders = {
            orderCustomizeProducts: orderCustomizeProducts,
            shippingAddress: shipAddress,
            paymentMethod: readyListmodel.payment,
            paymentScheme: PAYMENT_SCHEME.SELLER,
            platform: PLATFORM.Internal,
            readyId: readyListmodel.id,
            status: STATUS_TRANSACTION.WAITING_CONFIRM,
            totalPrice: readyListmodel.totalPrice,
            totalSalePrice: readyListmodel.totalSalePrice,
            shippingFee: 0,
            packageCost: 0,
            totalDiscount: readyListmodel.discountVoucher,
            totalCampaign: readyListmodel.discountCampaign,
            finallyPrice: readyListmodel.finnallyPrice,
            voucherPlatform: readyListmodel.discountVoucher,
            cODAmount: readyListmodel.finnallyPrice,
        };
        const [err, data] = await OrderServices.CreateOrder(orders);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Tạo đơn hàng thành công");
            return data;
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Tạo đơn hàng thất bại");
        }

        setLoading(false);
        return null;
    };

    const handleSentInbox = async (model) => {
        setLoading(true);
        const [err, data] = await ReadyListServices.SendInbox(model);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Gửi tin nhắn thành công");
            setLoading(false);
            return data;
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Gửi tin nhắn thất bại");
            setLoading(false);
            return null;
        }
    };

    const handleMarkIsSentInbox = async (id) => {
        setLoading(true);
        const [err, data] = await ReadyListServices.SetIsSentInbox(id);
        if (!err && data) {
            setLoading(false);
            return data;
        } else {
            setLoading(false);
            return null;
        }
    };

    const handleSave = async (isBuyNow = false) => {
        setLoading(true);

        //validate thông tin
        if (!isValid()) {
            setLoading(false);
            return null;
        }

        //ds sản phẩm
        let readyItems = [...listProduct];
        if (!readyItems || readyItems.length == 0) {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Vui lòng chọn sản phẩm");
            setLoading(false);
            return null;
        }

        let lstItem = [];
        for (var readyItem of readyItems) {
            let lstSize = readyItem.listSize.filter((x) => x.quantity > 0);
            if (lstSize.length == 0) continue;
            for (const sizeItem of lstSize) {
                let element = { ...initReadyListItem };
                element.objectType = ReadyObjectType.Design;
                element.objectId = readyItem.id;
                element.appProductVariantId = sizeItem.appProductVariantId;
                element.colorValue = readyItem.colorCode;
                element.sizeValue = sizeItem.size;
                element.quantity = sizeItem.quantity;
                element.productCost = sizeItem.productCost;
                element.appProductId = readyItem.appProductId;
                element.printCost = sizeItem.printCost;
                element.sellPrice = sizeItem.price;
                element.salePrice = sizeItem.salePrice;
                element.discount = sizeItem.discountBrandVoucher;
                element.printType = readyItem.printType;
                lstItem.push(element);
            }
        }

        if (!lstItem || lstItem.length == 0) {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Vui lòng chọn sản phẩm");
            setLoading(false);
            return null;
        }

        //tính toán lại price trên model chính
        let totalPrice = 0;
        let totalSalePrice = 0;
        lstItem.forEach((size) => {
            totalPrice += size.quantity * size.sellPrice;
            totalSalePrice += size.quantity * size.salePrice;
        });
        let [totalCampaign, scheme] = _handleMegaCampaign(totalSalePrice);
        let finnallyPrice = totalSalePrice - totalCampaign;
        let readyList = { ...readyListmodel };
        let readyShip = { ...readyListShipModel };
        readyList.readyListShip = readyShip;
        readyList.readyListItems = lstItem;
        readyList.totalPrice = totalPrice;
        readyList.totalSalePrice = totalSalePrice;
        readyList.finnallyPrice = finnallyPrice;
        readyList.discountCampaign = totalCampaign;
        readyList.discountVoucher = totalPrice - totalSalePrice;
        readyList.isBuyNow = isBuyNow;

        // call api
        const [err, data] = await ReadyListServices.CreateUpdateReadyList(readyList);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thông tin thành công");
            setLoading(false);
            return data;
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thông tin thất bại");
            setLoading(false);
            return null;
        }
    };

    const ListSelectedDesignId = useMemo(() => {
        let lst = [];
        if (listProduct && listProduct.length > 0)
            listProduct.forEach((item) => {
                lst.push(item.id);
            });
        return lst;
    }, [listProduct]);

    return (
        <React.Fragment>
            <Loading show={loading} fullScreen={true} />
            <Wrapper>
                <section className="customer-basic">
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="" className="required">
                                Tên khách hàng
                            </label>
                            <Textbox
                                id="customer"
                                name="customer"
                                placeholder="Tên khách hàng"
                                value={readyListmodel.customerName}
                                onChanged={(name, value) => {
                                    setReadyListModel({ ...readyListmodel, customerName: value });
                                }}
                                required
                                ref={addRef}
                            />
                        </div>
                        <div className="form-group inbox-link">
                            <label htmlFor="">Link hội thoại</label>
                            <Textbox
                                id="facebookId"
                                name="facebookId"
                                placeholder="Link hội thoại"
                                value={readyListmodel.facebookId}
                                onChanged={(name, value) => {
                                    setReadyListModel({ ...readyListmodel, facebookId: value });
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Trạng thái</label>
                            <span>{readyListmodel.statusName}</span>
                        </div>
                    </div>
                </section>
                <section className="list-product">
                    <div className="list-action">
                        <button
                            className="btn btn-custom"
                            onClick={(e) => {
                                setShowModal(true);
                            }}
                        >
                            Thêm sản phẩm
                        </button>
                    </div>
                    <div className="table-product">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th className="center">Mặt trước</th>
                                    <th className="center">Mặt sau</th>
                                    <th className="center">Tên sản phẩm</th>
                                    <th className="center" width="10%">
                                        Dòng sản phẩm
                                    </th>
                                    <th className="center" width="10%">
                                        Màu
                                    </th>
                                    <th className="center" width="40%">
                                        Size và số lượng
                                    </th>
                                    <th className="center" width="5%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {listProduct &&
                                    listProduct.map((item, idx) => {
                                        let frontUrl = "";
                                        let backUrl = "";
                                        if (item.templateImages) {
                                            //trước
                                            let itemFront = item.templateImages.find((x) => x.artworkType == ARTWORK_TYPE.Front);
                                            frontUrl = itemFront ? itemFront.productImageUrl : "";

                                            //sau
                                            let itemBack = item.templateImages.find((x) => x.artworkType == ARTWORK_TYPE.Back);
                                            backUrl = itemBack ? itemBack.productImageUrl : "";
                                        }

                                        return (
                                            <tr key={idx}>
                                                <td className="center">{idx + 1}</td>
                                                <td
                                                    className="center"
                                                    onClick={(e) => {
                                                        setShowImageModal(true);
                                                        setShowListImage(item.templateImages);
                                                    }}
                                                >
                                                    {frontUrl && <img src={frontUrl} alt="" className="product-thumbnail" />}
                                                </td>
                                                <td
                                                    className="center"
                                                    onClick={(e) => {
                                                        setShowImageModal(true);
                                                        setShowListImage(item.templateImages);
                                                    }}
                                                >
                                                    {backUrl && <img src={backUrl} alt="" className="product-thumbnail" />}
                                                </td>
                                                <td>
                                                    <p
                                                        className="product-name cursor-pointer"
                                                        onClick={(e) => {
                                                            if (item.merchantLink) window.open(item.merchantLink, "_blank");
                                                        }}
                                                    >
                                                        {item.title}
                                                    </p>
                                                    <p className="product-price">
                                                        <span className="sale-price">{Common.formatCurrency(item.salePrice)}</span>
                                                        <span className="sell-price">{Common.formatCurrency(item.price)}</span>
                                                    </p>
                                                    {/* <p className="product-link">https://ranus.vn/af/y9Un6y</p> */}
                                                </td>
                                                <td className="center position-relative">
                                                    {item.productOptions && (
                                                        <TFUSelect
                                                            placeholder="Chọn dòng sản phẩm"
                                                            options={item.productOptions}
                                                            value={item.appProductId}
                                                            onChanged={async (newVal) => {
                                                                //cập nhật lại appProductId
                                                                let lstP = [...listProduct];
                                                                let itemProduct = lstP[idx];
                                                                itemProduct.appProductId = +newVal;

                                                                //cập nhật lại màu sắc
                                                                let colorOptions = await getColorOptions(+newVal);
                                                                if (colorOptions && colorOptions.length > 0) {
                                                                    //kiểm tra sản phẩm hiện tại có đang mặc định
                                                                    //và trong list màu có đang mặc định hay không
                                                                    let colorCode = colorOptions[0].value;
                                                                    if (itemProduct.appProductId == itemProduct.sAppProductId) {
                                                                        let sColorCode = itemProduct.sColorCode;
                                                                        let findColor = colorOptions.find((x) => x.value == sColorCode);
                                                                        if (findColor) {
                                                                            colorCode = sColorCode;
                                                                            findColor.label = findColor.label + " (Mặc định)";
                                                                        }
                                                                    }

                                                                    itemProduct.colorCode = colorCode;
                                                                }
                                                                itemProduct.colorOptions = colorOptions;

                                                                //cập nhập lại list size
                                                                itemProduct.listSize = await getSize({
                                                                    appProductId: +newVal,
                                                                    colorCode: itemProduct.colorCode,
                                                                    designId: item.id,
                                                                });

                                                                //set thông tin
                                                                setListProduct(lstP);
                                                            }}
                                                        />
                                                    )}

                                                    {(item.sAppProductId != item.appProductId || item.sColorCode != item.colorCode) && (
                                                        <div
                                                            className="reset-source"
                                                            onClick={async (e) => {
                                                                let lstP = [...listProduct];
                                                                lstP[idx].appProductId = item.sAppProductId;
                                                                lstP[idx].colorCode = item.sColorCode;
                                                                lstP[idx].listSize = await getSize({
                                                                    appProductId: item.sAppProductId,
                                                                    colorCode: item.sColorCode,
                                                                    designId: item.id,
                                                                });
                                                                setListProduct(lstP);
                                                            }}
                                                        >
                                                            <div className="icon">
                                                                <img src={Reset} alt="" />
                                                            </div>
                                                        </div>
                                                    )}
                                                </td>
                                                <td className="center">
                                                    <TFUSelect
                                                        placeholder="Chọn màu"
                                                        options={item.colorOptions}
                                                        value={item.colorCode}
                                                        onChanged={(newVal) => {
                                                            //kiểm tra có khác với màu mặc định không
                                                            let diffDefault = item.appProductId == item.sAppProductId && newVal != item.sColorCode;

                                                            const configSize = async () => {
                                                                //cập nhật lại màu sắc
                                                                let lstP = [...listProduct];
                                                                lstP[idx].colorCode = newVal;

                                                                //lấy ra list size theo màu
                                                                let lstSize = await getSize({
                                                                    appProductId: item.appProductId,
                                                                    colorCode: newVal,
                                                                    designId: item.id,
                                                                });

                                                                lstP[idx].listSize = lstSize;
                                                                setListProduct(lstP);
                                                            };

                                                            //khác mặc định
                                                            if (diffDefault) {
                                                                ConfirmDialog("CẢNH BÁO", "Chọn khác màu gốc có thể sẽ ảnh hưởng đếnchất lượng hình in.", () => {
                                                                    return new Promise((resolve, reject) => {
                                                                        configSize();
                                                                        resolve({ title: "Thành công", msg: "Đổi màu thành công." });
                                                                    });
                                                                });
                                                            } else {
                                                                configSize();
                                                            }
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <div className="list-quantity">
                                                        {item.listSize &&
                                                            item.listSize.map((size, i) => {
                                                                return (
                                                                    <div className={`quantity-item ${size.quantity > 0 ? "active" : ""} ${size.sellableQuantity > 0 ? "" : "disabled"}`} key={i}>
                                                                        <span className="size-name">{size.size}</span>
                                                                        <div className="quantity-action">
                                                                            <div
                                                                                className="increase-quantity"
                                                                                onClick={(e) => {
                                                                                    let lstP = [...listProduct];
                                                                                    lstP[idx].listSize[i].quantity = lstP[idx].listSize[i].quantity + 1;
                                                                                    setListProduct(lstP);

                                                                                    //tính toán lại price trên model chính
                                                                                    let totalPrice = 0;
                                                                                    let totalSalePrice = 0;

                                                                                    lstP.forEach((item) => {
                                                                                        item.listSize.forEach((size) => {
                                                                                            totalPrice += size.quantity * size.price;
                                                                                            totalSalePrice += size.quantity * size.salePrice;
                                                                                        });
                                                                                    });
                                                                                    let [totalCampaign, scheme] = _handleMegaCampaign(totalSalePrice);
                                                                                    let finnallyPrice = totalSalePrice - totalCampaign;
                                                                                    setReadyListModel({
                                                                                        ...readyListmodel,
                                                                                        totalPrice: totalPrice,
                                                                                        totalSalePrice: totalSalePrice,
                                                                                        finnallyPrice: finnallyPrice,
                                                                                        discountCampaign: totalCampaign,
                                                                                    });
                                                                                }}
                                                                            >
                                                                                <div className="icon">
                                                                                    <img src={Plus} alt="" />
                                                                                </div>
                                                                            </div>
                                                                            <span>{size.quantity}</span>
                                                                            <div
                                                                                className="decrease-quantity"
                                                                                onClick={(e) => {
                                                                                    let lstP = [...listProduct];
                                                                                    let currentQuantity = lstP[idx].listSize[i].quantity;
                                                                                    if (currentQuantity <= 0) return;
                                                                                    lstP[idx].listSize[i].quantity = lstP[idx].listSize[i].quantity - 1;
                                                                                    setListProduct(lstP);

                                                                                    //tính toán lại price trên model chính
                                                                                    let totalPrice = 0;
                                                                                    let totalSalePrice = 0;
                                                                                    lstP.forEach((item) => {
                                                                                        item.listSize.forEach((size) => {
                                                                                            totalPrice += size.quantity * size.price;
                                                                                            totalSalePrice += size.quantity * size.salePrice;
                                                                                        });
                                                                                    });
                                                                                    let [totalCampaign, scheme] = _handleMegaCampaign(totalSalePrice);
                                                                                    let finnallyPrice = totalSalePrice - totalCampaign;
                                                                                    setReadyListModel({
                                                                                        ...readyListmodel,
                                                                                        totalPrice: totalPrice,
                                                                                        totalSalePrice: totalSalePrice,
                                                                                        finnallyPrice: finnallyPrice,
                                                                                        discountCampaign: totalCampaign,
                                                                                    });
                                                                                }}
                                                                            >
                                                                                <div className="icon">
                                                                                    <img src={Minus} alt="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                </td>
                                                <td className="center">
                                                    <a
                                                        className=""
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            let lstP = [...listProduct];
                                                            lstP.splice(idx, 1);
                                                            setListProduct(lstP);

                                                            //tính toán lại price trên model chính
                                                            let totalPrice = 0;
                                                            let totalSalePrice = 0;
                                                            lstP.forEach((item) => {
                                                                item.listSize.forEach((size) => {
                                                                    totalPrice += size.quantity * size.price;
                                                                    totalSalePrice += size.quantity * size.salePrice;
                                                                });
                                                            });
                                                            let [totalCampaign, scheme] = _handleMegaCampaign(totalSalePrice);
                                                            let finnallyPrice = totalSalePrice - totalCampaign;
                                                            setReadyListModel({
                                                                ...readyListmodel,
                                                                totalPrice: totalPrice,
                                                                totalSalePrice: totalSalePrice,
                                                                finnallyPrice: finnallyPrice,
                                                                discountCampaign: totalCampaign,
                                                            });
                                                        }}
                                                    >
                                                        <img src={Trash} alt="" />
                                                    </a>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                </section>
                <section className="order-basic">
                    <section className="order-address">
                        <label htmlFor="" className="required">
                            Địa chỉ nhận hàng
                        </label>
                        <div className="form-row">
                            <div className="form-left">
                                <div className="form-group">
                                    <Textbox
                                        id="userName"
                                        name="userName"
                                        placeholder="Tên người nhận"
                                        value={readyListShipModel.userName}
                                        onChanged={(name, value) => {
                                            setReadyListShipModel({ ...readyListShipModel, userName: value });
                                        }}
                                        required
                                        ref={addRef}
                                    />
                                </div>
                                <div className="form-group">
                                    <Textbox
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        placeholder="Số điện thoại"
                                        number
                                        value={readyListShipModel.phoneNumber}
                                        onChanged={(name, value) => {
                                            setReadyListShipModel({ ...readyListShipModel, phoneNumber: value });
                                        }}
                                        required
                                        ref={addRef}
                                    />
                                </div>
                            </div>
                            <div className="form-right">
                                <div className="form-group">
                                    <TFUSelect
                                        placeholder="Chọn Tỉnh/Thành phố"
                                        value={readyListShipModel.provinceCode}
                                        options={provinces}
                                        onChanged={(newVal) => {
                                            //set thông tin
                                            setReadyListShipModel({ ...readyListShipModel, provinceCode: newVal, districtCode: "", wardCode: "" });

                                            //lấy ra danh sách quận
                                            getDistricts(newVal);
                                        }}
                                        required
                                        ref={addRef}
                                        name="provinceCode"
                                        id="provinceCode"
                                    />
                                </div>
                                <div className="form-group">
                                    <TFUSelect
                                        placeholder="Chọn Quận/Huyện"
                                        value={readyListShipModel.districtCode}
                                        options={districts}
                                        onChanged={(newVal) => {
                                            //set thông tin
                                            setReadyListShipModel({ ...readyListShipModel, districtCode: newVal, wardCode: "" });

                                            //lấy ra danh sách phường
                                            getWards(newVal);
                                        }}
                                        required
                                        ref={addRef}
                                        name="districtCode"
                                        id="districtCode"
                                    />
                                </div>
                                <div className="form-group">
                                    <TFUSelect
                                        placeholder="Chọn Phường/Xã"
                                        value={readyListShipModel.wardCode}
                                        options={wards}
                                        onChanged={(newVal) => {
                                            setReadyListShipModel({ ...readyListShipModel, wardCode: newVal });
                                        }}
                                        required
                                        ref={addRef}
                                        name="wardCode"
                                        id="wardCode"
                                    />
                                </div>
                                <div className="form-group form-street">
                                    <Textbox
                                        id="address"
                                        name="address"
                                        placeholder="Số nhà/Tên đường/ Tổ/ Ấp/ Xóm"
                                        value={readyListShipModel.address}
                                        onChanged={(name, value) => {
                                            setReadyListShipModel({ ...readyListShipModel, address: value });
                                        }}
                                        required
                                        ref={addRef}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="order-pre">
                        <label htmlFor="">Thanh toán trước</label>
                        <div className="list-payment">
                            <div className="radio-btn">
                                <input
                                    type="radio"
                                    checked={readyListmodel.payment == PAYMENT_METHOD.MOMO_WALLET}
                                    onChange={(e) => {
                                        setReadyListModel({ ...readyListmodel, payment: PAYMENT_METHOD.MOMO_WALLET });
                                    }}
                                    name="radio-momo"
                                    id="radio-momo"
                                />
                                <label htmlFor="radio-momo">MoMo</label>
                            </div>
                            <div className="radio-btn">
                                <input
                                    type="radio"
                                    checked={readyListmodel.payment == PAYMENT_METHOD.INTERNET_BANKING}
                                    onChange={(e) => {
                                        setReadyListModel({ ...readyListmodel, payment: PAYMENT_METHOD.INTERNET_BANKING });
                                    }}
                                    name="radio-internet-banking"
                                    id="radio-internet-banking"
                                />
                                <label htmlFor="radio-internet-banking">Ngân hàng</label>
                            </div>
                        </div>
                    </section>
                    <section className="order-deposit">
                        <label htmlFor="">Đặt cọc</label>
                        <div className="list-payment">
                            <div className="radio-btn">
                                <input
                                    type="radio"
                                    checked={readyListmodel.payment == PAYMENT_METHOD.COD_TO_TRANSFER}
                                    onChange={(e) => {
                                        setReadyListModel({ ...readyListmodel, payment: PAYMENT_METHOD.COD_TO_TRANSFER });
                                    }}
                                    name="radio-cod-to-transfer"
                                    id="radio-cod-to-transfer"
                                />
                                <label htmlFor="radio-cod-to-transfer">Ngân hàng</label>
                            </div>
                        </div>
                    </section>
                    <section className="order-cod">
                        <label htmlFor="">Thanh toán khi nhận hàng</label>
                        <div className="list-payment">
                            <div className="radio-btn">
                                <input
                                    type="radio"
                                    checked={readyListmodel.payment == PAYMENT_METHOD.COD}
                                    onChange={(e) => {
                                        setReadyListModel({ ...readyListmodel, payment: PAYMENT_METHOD.COD });
                                    }}
                                    name="radio-cod"
                                    id="radio-cod"
                                />
                                <label htmlFor="radio-cod">COD</label>
                            </div>
                        </div>
                    </section>
                </section>
                <section className="order-total">
                    <label htmlFor="">Tổng tiền thanh toán</label>
                    <div className="price-list">
                        <div className="price-item">
                            <span>Giá gốc</span>
                            <span className="ml-auto">{Common.formatCurrency(readyListmodel.totalPrice)}</span>
                        </div>
                        <div className="price-item">
                            <span>Giá giảm</span>
                            <span className="ml-auto">{Common.formatCurrency(readyListmodel.totalSalePrice)}</span>
                        </div>
                        <div className="price-item">
                            <span>Giảm giá 5/10%</span>
                            <span className="ml-auto">-{Common.formatCurrency(readyListmodel.discountCampaign)}</span>
                        </div>
                        <div className="price-item">
                            <span>Phí vận chuyển</span>
                            <span className="ml-auto">0đ</span>
                        </div>
                        <hr />
                        <div className="price-item">
                            <span>Tổng tiền</span>
                            <span className="ml-auto">{Common.formatCurrency(readyListmodel.finnallyPrice)}</span>
                        </div>
                    </div>
                </section>
                <div className="list-bottom-action">
                    <button
                        className="btn btn-custom m-2 position-relative"
                        onClick={(e) => {
                            //kiểm tra link hội thoại tồn tại
                            let conversionLink = readyListmodel.facebookId;
                            if (!conversionLink || conversionLink == "") {
                                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Vui lòng nhập link hội thoại");
                                return;
                            }

                            //kiểm tra link hội thoại có đúng format hay không
                            //https://pancake.vn/AI.for.Vietnamese?c_id=139113619287810_7842455392448031
                            let uri = new URL(conversionLink);
                            let query = uri.searchParams;
                            let c_id = query.get("c_id");
                            let splitId = c_id != null && c_id != "" ? c_id.split("_") : [];
                            let pageId = 0;
                            let userId = 0;
                            if (splitId.length == 2) {
                                pageId = splitId[0];
                                userId = splitId[1];
                            }

                            if (pageId == 0 || userId == 0) {
                                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Link hội thoại không đúng định dạng");
                                return;
                            }

                            //nếu đúng định dạnh tiến hành save và gửi link
                            handleSave().then((res) => {
                                if (res) {
                                    //tiến hành gửi tin nhắn
                                    let model = {
                                        conversionId: c_id,
                                        pageId: pageId,
                                        message: `Đây là link mua hàng của bạn: https://ranus.vn/checkout-ready/${res.id}`,
                                    };

                                    handleSentInbox(model).then((data) => {
                                        if (data) {
                                            handleMarkIsSentInbox(res.id).then((res) => {
                                                if (res) {
                                                    Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Gửi link mua hàng thành công");
                                                    history.push("/manage-ready-to-ship-list");
                                                }
                                            });
                                        }
                                    });
                                }
                            });
                        }}
                    >
                        Gửi link mua hàng
                        {readyListmodel && readyListmodel.isSentInbox && (
                            <div className="icon sent">
                                <img src={Sent} alt="" />
                            </div>
                        )}
                    </button>
                    <button
                        className="btn btn-custom m-2"
                        onClick={(e) => {
                            handleSave().then((res) => {
                                if (res) {
                                    navigator.clipboard.writeText(`https://ranus.vn/checkout-ready/${res.id}`);
                                    history.push("/manage-ready-to-ship-list");
                                }
                            });
                        }}
                    >
                        Lưu và Copy link
                    </button>
                    <button
                        className="btn btn-custom m-2"
                        onClick={(e) => {
                            //kiểm tra phương thức thanh toán, nếu là MoMo thì thông báo
                            if (readyListmodel.payment == PAYMENT_METHOD.MOMO_WALLET) {
                                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Chưa hỗ trợ thanh toán MoMo cho đơn đặt hàng ngay");
                                return;
                            }

                            handleSave(true).then((res) => {
                                if (res) {
                                    handleBuy(res).then((data) => {
                                        if (data) {
                                            //nếu là đặt cọc/ck ngân hàng thì popup trang thanh toán
                                            if (readyListmodel.payment != PAYMENT_METHOD.COD) {
                                                window.open(data.payUrl, "_blank");
                                            }
                                            history.push("/manage-ready-to-ship-list");
                                        }
                                    });
                                }
                            });
                        }}
                    >
                        Đặt hàng
                    </button>
                </div>
            </Wrapper>
            <ShowImageModal
                show={showImageModal}
                onClose={() => {
                    setShowImageModal(false);
                    setShowListImage([]);
                }}
                listImage={showListImage}
            />
            <ReadyToShipProductModal
                show={showModal}
                onClose={() => setShowModal(false)}
                onSelect={async (lst) => {
                    setShowModal(false);
                    if (lst) {
                        setLoading(true);
                        for (var item of lst) {
                            //sản phẩm
                            let productOptions = await getProductOptions(item.packageType);
                            if (productOptions && productOptions.length > 0) {
                                //kiểm tra trong ds sản phẩm có sản phẩm mặc định hay không
                                let anySourceAppProductId = productOptions.find((x) => +x.value == item.sAppProductId);
                                if (anySourceAppProductId) {
                                    //chọn mặc định sản phẩm
                                    item.appProductId = item.sAppProductId;

                                    //thêm vào label chữ mặc định
                                    anySourceAppProductId.label = anySourceAppProductId.label + " (Mặc định)";
                                } else item.appProductId = +productOptions[0].value;
                            }
                            item.productOptions = productOptions;

                            //màu sắc
                            let colorOptions = await getColorOptions(item.appProductId);
                            if (colorOptions && colorOptions.length > 0) {
                                //kiểm tra trong ds màu có màu mặc định hay không
                                let anySourceColor = colorOptions.find((x) => x.value == item.sColorCode);
                                if (anySourceColor) {
                                    //chọn mặc định màu
                                    item.colorCode = item.sColorCode;

                                    //thêm vào label chữ mặc định
                                    anySourceColor.label = anySourceColor.label + " (Mặc định)";
                                } else item.colorCode = colorOptions[0].value;
                            }
                            item.colorOptions = colorOptions;

                            //lấy ra list size
                            if (item.appProductId > 0 && item.colorCode != "") {
                                let lstSize = await getSize({
                                    appProductId: item.appProductId,
                                    colorCode: item.colorCode,
                                    designId: item.id,
                                });
                                item.listSize = lstSize;
                            } else {
                                item.listSize = [];
                            }
                        }

                        let lstItem = [...listProduct, ...lst];
                        setListProduct(lstItem);
                        setLoading(false);
                    }
                }}
                listSelectedDesignId={ListSelectedDesignId}
            />
        </React.Fragment>
    );
}

export default ReadyToShipList;

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    .form-group.inbox-link {
        width: 30% !important;
    }

    .form-group.inbox-link input#facebookId {
        width: 100%;
    }

    .icon.sent {
        position: absolute;
        top: -1px;
        right: -1px;
    }
    .reset-source {
        position: absolute;
        left: -16px;
        top: calc(50% - 12px);
    }
    .quantity-item.active .quantity-action {
        background: #ffe3c0;
    }

    .quantity-item.active .quantity-action .increase-quantity {
        background: #e1b47a;
    }

    .quantity-item.active .quantity-action .decrease-quantity {
        background: #e1b47a;
    }

    section.order-address {
        padding: 20px 25px;
        background: white;
        width: 100%;
    }

    .form-left {
        width: 30%;
    }

    .form-right {
        flex: 1;
        margin-left: 20px;
        display: flex;
        flex-wrap: wrap;
    }

    .form-left input[type="text"] {
        width: 100%;
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 7px 12px;
    }

    .form-left input[type="number"] {
        width: 100%;
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 7px 12px;
    }

    .form-right .form-group {
        width: calc(33.3% - 20px);
        margin: 0 10px;
    }

    .form-group.form-street {
        width: 100%;
    }

    .form-group.form-street input[type="text"] {
        width: 100%;
        padding: 7px 12px;
        border: 1px solid #d9d9d9;
        background: #fff;
    }

    section.order-basic {
        display: flex;
        flex-wrap: wrap;
    }

    section.order-pre {
        width: calc(33% - 20px);
        margin: 10px 10px 10px 0;
        padding: 20px 25px;
        background: white;
    }

    section.order-deposit {
        width: calc(33% - 20px);
        margin: 10px;
        padding: 20px 25px;
        background: white;
    }

    section.order-cod {
        margin: 10px 0 10px 10px;
        padding: 20px 25px;
        background: white;
        flex: 1;
    }

    .radio-btn {
        display: flex;
        align-items: center;
        margin: 10px 0;
    }

    .radio-btn label {
        margin: 0 0 0 10px;
    }

    .order-total label {
        color: #444;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .price-item {
        display: flex;
        margin: 10px 0;
    }

    .price-item span.ml-auto {
        color: #666f7b;
        text-align: right;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .price-item span {
        color: #666f7b;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .list-bottom-action {
        margin-left: auto;
    }

    /* thông tin section */
    section.order-basic {
        width: 70%;
        margin: 20px;
    }

    section.order-total {
        width: calc(30% - 80px);
        margin: 20px;
        padding: 20px 25px;
        background: white;
    }

    section.customer-basic {
        width: 100%;
    }

    section.list-product {
        width: 100%;
    }

    /* danh sách sản phẩm */
    section.list-product {
        margin: 20px;
        padding: 20px 25px;
        background: white;
    }

    .list-action {
        margin-bottom: 18px;
    }

    .table-product thead {
        border: 1px solid #d9d9d9;
        background: #d9d9d9;
    }

    img.product-thumbnail {
    }

    img.product-thumbnail {
        width: 90px;
        height: 90px;
        object-fit: contain;
    }

    .list-quantity {
        display: flex;
        justify-content: center;
    }

    .quantity-item {
        margin: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    span.size-name {
        color: #444;
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        margin-bottom: 4px;
    }

    .quantity-action {
        display: flex;
        flex-direction: column;
        border: 1px solid #d9d9d9;
        align-items: center;
    }

    .increase-quantity {
        padding: 4px 8px;
        border: 1px solid #d9d9d9;
        background: #eaeaea;
        font-weight: 600;
    }

    .quantity-action span {
        color: #444;
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        padding: 2px;
    }

    .decrease-quantity {
        padding: 4px 8px;
        border: 1px solid #d9d9d9;
        background: #eaeaea;
        font-weight: 600;
    }

    p.product-name {
        color: #444;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    span.sale-price {
        color: #f96a74;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    span.sell-price {
        color: #a5a5a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration: line-through;
        margin-left: 4px;
    }

    p.product-link {
        color: #13aaff;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    /* thông tin cơ bản */
    section.customer-basic {
        margin: 20px;
        padding: 20px 25px;
        background: white;
    }

    section.customer-basic .form-group {
        display: flex;
        flex-direction: column;
        margin: 0 10px;
        width: 10%;
    }

    section.customer-basic .form-group input[type="text"] {
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 8px 12px;
    }
`;
