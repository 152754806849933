import BaseServices from "./BaseServices";

const ReadyListServices = {
    GetReadyProductOptions: async (packageType) => {
        return await BaseServices.Get(`/api/ReadyList/get-ready-product-options/${packageType}`);
    },
    GetReadyProductColorOptions: async (appProductId) => {
        return await BaseServices.Get(`/api/ReadyList/get-ready-product-color-options/${appProductId}`);
    },
    GetReadyProductSizeOptions: async (model) => {
        return await BaseServices.Post(`/api/ReadyList/get-ready-product-size-options`, model);
    },
    CreateUpdateReadyList: async (model) => {
        return await BaseServices.Post(`/api/ReadyList/create-update-ready-list`, model);
    },
    GetReadyListByPage: async (model) => {
        return await BaseServices.Post(`/api/ReadyList/get-ready-list-by-page`, model);
    },
    GetReadyById: async (id) => {
        return await BaseServices.Get(`/api/ReadyList/get-ready-by-id/${id}`);
    },
    SetIsDelete: async (id) => {
        return await BaseServices.Get(`/api/ReadyList/set-is-delete/${id}`);
    },
    SetIsArchive: async (id) => {
        return await BaseServices.Get(`/api/ReadyList/set-is-archive/${id}`);
    },
    SendInbox: async (model) => {
        return await BaseServices.Post(`/api/ReadyList/send-inbox`, model);
    },
    SetIsSentInbox: async (id) => {
        return await BaseServices.Get(`/api/ReadyList/set-is-sent-inbox/${id}`);
    },
};

export default ReadyListServices;
