import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Copy from "./assets/Copy.svg";
import { useHistory } from "react-router-dom";
import { INITPAGING, NOTIFY, PAYMENT_METHOD } from "../based/Constants";
import ReadyListServices from "../based/services/ReadyListServices";
import Common from "../based/Common";
import CommonTablePagination from "../based/CommonTablePagination";
import { Notify } from "../based/Notify";
import OpenLink from "./assets/OpenLink.svg";
import ChangePaymentMethodModal from "../manage-order/ChangePaymentMethodModal";
import QRModal from "../manage-order/QRModal";
import TFUDatetime from "../based/inputs/TFUDatetime";

const ReadyListStatus = {
    NotClick: 0,
    Clicked: 1,
    Confirmed: 2,
};

const ProductTab = {
    ListProduct: 0,
    Order: 1,
    Archive: 2,
};

function ManageReadyToShipList(props) {
    const history = useHistory();
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState({ ...INITPAGING, orderDirection: "desc", fromDate: new Date(2025, 0, 1), toDate: new Date() });
    const [showManualTransfer, setShowManualTransfer] = useState(false);
    const [selectOrderId, setSelectOrderId] = useState(0);
    const [showQrModal, setShowQrModal] = useState(false);
    const [showFinnalyPrice, setShowFinnalyPrice] = useState(0);

    useEffect(() => {
        getDatas(paging);
    }, []);

    const getDatas = async (page) => {
        const [err, data] = await ReadyListServices.GetReadyListByPage(page);
        if (!err && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setPaging(page);
        }
    };

    const setIsDelete = async (id) => {
        const [err, data] = await ReadyListServices.SetIsDelete(id);
        if (!err && data) {
            getDatas(paging);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Xóa thành công");
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Có lỗi xảy ra");
        }
    };

    const setIsArchive = async (id) => {
        const [err, data] = await ReadyListServices.SetIsArchive(id);
        if (!err && data) {
            getDatas(paging);
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Archive thành công");
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Có lỗi xảy ra");
        }
    };

    function renderBody() {
        let content = [];
        if (!datas || datas.length === 0) return "";

        datas.map((order, index) => {
            let firstColumn = [];
            let lastColumn = [];
            let rowSpan = order.groupItems && order.groupItems.length;

            //stt
            firstColumn.push(
                <td className="center" rowSpan={rowSpan}>
                    {index + 1}
                </td>
            );

            //thông tin khách hàng
            firstColumn.push(
                <td rowSpan={rowSpan}>
                    <div className="list-info">
                        <div className="info-item">
                            <span className="title">Tên</span>
                            <input type="text" className="edit-content disabled" value={order.customerName} readOnly />
                        </div>
                        <div className="info-item">
                            <span className="title">PSID</span>
                            <textarea type="text" className="edit-content disabled" value={order.facebookId} readOnly />
                        </div>
                        <div className="info-item">
                            <span className="title">TG Tạo</span>
                            <span className="edit-content">{Common.formatDate(new Date(order.createdDate), "fulldate")}</span>
                        </div>
                        {order.updatedDate && (
                            <div className="info-item">
                                <span className="title">TG Đặt</span>
                                <span className="edit-content">{Common.formatDate(new Date(order.updatedDate), "fulldate")}</span>
                            </div>
                        )}
                        {order.status != ReadyListStatus.Confirmed && (
                            <div className="info-item">
                                <span className="title">Link list sp</span>
                                <span className="edit-content link">https://ranus.vn/checkout-ready/{order.id}</span>
                            </div>
                        )}
                        {order.status != ReadyListStatus.Confirmed && (
                            <div className="action-item-list">
                                <div className="action-item">
                                    <a
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigator.clipboard.writeText(`https://ranus.vn/checkout-ready/${order.id}`);
                                            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Copy link thành công");
                                        }}
                                    >
                                        <div className="icon">
                                            <img src={Copy} />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                </td>
            );

            //thanh toán
            lastColumn.push(
                <td className="payment-inf" rowSpan={rowSpan}>
                    <div className="payment-info">
                        <span className="title">Phương thức thanh toán</span>
                        <span className="edit-content">{order.paymentName}</span>
                    </div>
                    <div className="payment-info">
                        <span className="title">Tổng tiền</span>
                        <span className="edit-content">{Common.formatCurrency(order.finnallyPrice)}</span>
                    </div>
                    <div className="payment-info">
                        <span className="title">Đã thanh toán</span>
                        <span className="edit-content">{Common.formatCurrency(order.transferPartial)}</span>
                    </div>
                    <div className="payment-info">
                        <span className="title">Còn lại</span>
                        <span className="edit-content">{Common.formatCurrency(order.finnallyPrice - order.transferPartial)}</span>
                    </div>

                    <div className="list-transfer-action">
                        {order.orderId > 0 && (order.payment == PAYMENT_METHOD.COD || order.payment == PAYMENT_METHOD.COD_TO_TRANSFER) && (
                            <div className="transfer-action-item">
                                <button
                                    className="btn btn-secondary m-1"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSelectOrderId(order.orderId);
                                        setShowManualTransfer(true);
                                    }}
                                >
                                    Upload mã thẻ
                                </button>
                            </div>
                        )}
                        {order.orderId > 0 && (order.payment == PAYMENT_METHOD.COD || order.payment == PAYMENT_METHOD.COD_TO_TRANSFER) && (
                            <div className="transfer-action-item">
                                <button
                                    className="btn btn-custom m-1"
                                    onClick={(e) => {
                                        setShowQrModal(true);
                                        setSelectOrderId(order.orderId);
                                        setShowFinnalyPrice(order.finnallyPrice - order.transferPartial);
                                    }}
                                >
                                    Tạo QR Chuyển Khoản
                                </button>
                            </div>
                        )}
                    </div>

                    {/* <div className="payment-info">
                        <span className="title">Đã thanh toán</span>
                        <span className="edit-content">1.000.000đ</span>
                    </div>
                    <div className="payment-info">
                        <span className="title">Còn nợ</span>
                        <span className="edit-content">1.000.000đ</span>
                    </div> */}
                </td>
            );

            //địa chỉ
            lastColumn.push(
                <td className="address-inf" rowSpan={rowSpan}>
                    {order.readyListShip && (
                        <React.Fragment>
                            <div className="address-info">
                                <span className="title">Tên</span>
                                <span className="edit-content">{order.readyListShip.userName}</span>
                            </div>
                            <div className="address-info">
                                <span className="title">SĐT</span>
                                <span className="edit-content">{order.readyListShip.phoneNumber}</span>
                            </div>
                            <div className="address-info">
                                <span className="title">Địa chỉ</span>
                                <span className="edit-content">{order.readyListShip.address}</span>
                            </div>
                            <div className="address-info">
                                <span className="title">Phường/Xã</span>
                                <span className="edit-content">{order.readyListShip.wardName}</span>
                            </div>
                            <div className="address-info">
                                <span className="title">Quận/Huyện</span>
                                <span className="edit-content">{order.readyListShip.districtName}</span>
                            </div>
                            <div className="address-info">
                                <span className="title">Tỉnh/Thành phố</span>
                                <span className="edit-content">{order.readyListShip.provinceName}</span>
                            </div>
                        </React.Fragment>
                    )}
                </td>
            );

            //trạng thái
            lastColumn.push(
                <td className="vertical-align-middle" rowSpan={rowSpan}>
                    <div className="status-info">
                        <span className="status">{order.statusName}</span>
                    </div>
                    {order.orderId > 0 && (
                        <div className="order-info">
                            <span className="title">Người đặt:</span>
                            <span className={`edit-content ${order.isSystem ? "danger" : ""}`}>{order.isSystem ? `Hệ thống (${order.customerUserName})` : "Khách hàng"}</span>
                        </div>
                    )}
                    {order.orderId > 0 && (
                        <div className="order-info">
                            <span className="title">Mã Ranus:</span>
                            <span className="edit-content">
                                <div
                                    className="icon cursor-pointer"
                                    onClick={(e) => {
                                        window.open(`https://admin.ranus.vn/order-detail/${order.orderId}`, "_blank");
                                    }}
                                >
                                    {order.orderId}
                                    <img src={OpenLink} />
                                </div>
                            </span>
                        </div>
                    )}
                    {order.orderNumber != "" && (
                        <div className="order-info">
                            <span className="title">Mã đơn hàng:</span>
                            <span
                                className="edit-content cursor-pointer"
                                onClick={(e) => {
                                    navigator.clipboard.writeText(order.orderNumber);
                                    Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Copy mã đơn hàng thành công");
                                }}
                            >
                                {order.orderNumber}
                            </span>
                        </div>
                    )}
                    {order.orderStatusName != "" && (
                        <div className="order-info">
                            <span className="title">Trạng thái đơn hàng:</span>
                            <span className="edit-content cursor-pointer">{order.orderStatusName}</span>
                        </div>
                    )}
                </td>
            );

            //hành động
            lastColumn.push(
                <td className="center" rowSpan={rowSpan}>
                    <div className="lst-action-item">
                        <button
                            className={`btn btn-custom m-1 ${order.status == ReadyListStatus.Confirmed ? "disabled" : ""}`}
                            onClick={(e) => {
                                e.preventDefault();
                                history.push(`/ready-to-ship-list/${order.id}`);
                            }}
                        >
                            Sửa
                        </button>
                        {paging.productTab == ProductTab.ListProduct && (
                            <button
                                className="btn btn-custom m-1"
                                onClick={(e) => {
                                    setIsArchive(order.id);
                                }}
                            >
                                Lưu trữ
                            </button>
                        )}
                        <button
                            className={`btn btn-danger m-1 ${order.status == ReadyListStatus.Confirmed ? "disabled" : ""}`}
                            onClick={(e) => {
                                setIsDelete(order.id);
                            }}
                        >
                            Xóa
                        </button>
                    </div>
                </td>
            );

            //danh sách sản phẩm
            let productColumn = [];
            if (order.groupItems)
                order.groupItems.map((product, index) => {
                    let productContent = [];
                    //hình
                    productContent.push(
                        <td>
                            <div className="list-item-thumbnail">
                                {product.templateImages &&
                                    product.templateImages.map((i, x) => {
                                        let image = i.productImageUrl ? i.productImageUrl.replace(".jpg", "_crop.webp") : "";
                                        if (!image) return "";
                                        return <img src={image} alt="" className="thumbnail" key={x} />;
                                    })}
                            </div>
                        </td>
                    );
                    //sản phẩm
                    productContent.push(
                        <td>
                            <div className="product-detail-info">
                                <div className="product-name">{product.designTitle}</div>
                                <div className="product-attr">
                                    {product.appProductName}, {product.colorDisplayName}
                                </div>
                                <div className="product-lst">
                                    {product.readyListItems &&
                                        product.readyListItems.map((i, x) => {
                                            return (
                                                <span className="badge badge-custom" key={x}>
                                                    {i.sizeValue}x{i.quantity}
                                                </span>
                                            );
                                        })}
                                </div>
                            </div>
                        </td>
                    );
                    productColumn.push(productContent);
                });

            //ghép các phần lại với nhau
            productColumn.map((product, idx) => {
                if (idx == 0) {
                    content.push(
                        <tr id={`${index}_o${idx}`} key={`${index}_o${idx}`}>
                            {firstColumn.map((i) => i)}
                            {product.map((i) => i)}
                            {lastColumn.map((i) => i)}
                        </tr>
                    );
                } else {
                    content.push(
                        <tr id={`${index}_o${idx}`} key={`${index}_o${idx}`}>
                            {product.map((i) => i)}
                        </tr>
                    );
                }
            });
        });
        return content;
    }

    function _renderBody() {
        if (datas && datas.length > 0) return <tbody>{renderBody()}</tbody>;
        else
            return (
                <tbody>
                    <tr>
                        <td colSpan="999" className="vertical-align-middle text-center">
                            Không có dữ liệu
                        </td>
                    </tr>
                </tbody>
            );
    }

    return (
        <Wrapper>
            <section className="action-list">
                <button
                    className="btn btn-custom"
                    onClick={(e) => {
                        e.preventDefault();
                        history.push("/ready-to-ship-list");
                    }}
                >
                    Tạo list mới
                </button>

                <div className="created-date-area">
                    <label>Từ ngày:</label>
                    <TFUDatetime
                        name="fromDate"
                        placeholder="Từ ngày"
                        value={new Date(paging.fromDate)}
                        onChanged={(value, name) => {
                            getDatas({ ...paging, pageNumber: 1, fromDate: new Date(value) });
                        }}
                        showDateOnly
                    />
                </div>
                <div className="created-date-area">
                    <label>Đến ngày:</label>
                    <TFUDatetime
                        name="toDate"
                        placeholder="Đến ngày"
                        value={new Date(paging.toDate)}
                        onChanged={(value, name) => {
                            getDatas({ ...paging, pageNumber: 1, toDate: new Date(value) });
                        }}
                        showDateOnly
                        maxDate={new Date()}
                    />
                </div>

                <div className="search-area-filter">
                    <div className="search-box">
                        <input
                            type="text"
                            value={paging.keyword}
                            onChange={(e) => {
                                setPaging({ ...paging, keyword: e.target.value });
                            }}
                            placeholder="Tìm kiếm Tên khách hàng/Người nhận/SĐT"
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    getDatas(paging);
                                }
                            }}
                        />
                        <div
                            className="search-button cursor-pointer"
                            onClick={(e) => {
                                getDatas(paging);
                            }}
                        >
                            <span>Tìm kiếm</span>
                        </div>
                    </div>
                </div>
            </section>
            <section className="table-manage">
                <ul className="nav nav-tabs tabs-bordered">
                    <li className="nav-item">
                        <a
                            className={`nav-link ${paging.productTab == ProductTab.ListProduct ? "active" : "btn-light"}`}
                            onClick={() => {
                                getDatas({ ...paging, productTab: ProductTab.ListProduct, pageNumber: 1 });
                            }}
                        >
                            <span>List sản phẩm</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${paging.productTab == ProductTab.Order ? "active" : "btn-light"}`}
                            onClick={() => {
                                getDatas({ ...paging, productTab: ProductTab.Order, pageNumber: 1 });
                            }}
                        >
                            <span>Đơn đã đặt</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${paging.productTab == ProductTab.Archive ? "active" : "btn-light"}`}
                            onClick={() => {
                                getDatas({ ...paging, productTab: ProductTab.Archive, pageNumber: 1 });
                            }}
                        >
                            <span>Lưu trữ</span>
                        </a>
                    </li>
                </ul>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th className="center" colSpan={2}>
                                Thông tin khách hàng
                            </th>
                            <th className="center">Hình</th>
                            <th className="center">Sản phẩm</th>
                            <th className="center">Thanh toán</th>
                            <th className="center">Địa chỉ</th>
                            <th className="center">Trạng thái</th>
                            <th className="center"></th>
                        </tr>
                    </thead>
                    {_renderBody()}
                </table>
                <CommonTablePagination
                    paging={paging}
                    onChangePage={(page) => {
                        getDatas({ ...paging, pageNumber: page });
                    }}
                    onChangePageSize={(pageSize) => {
                        getDatas({ ...paging, pageNumber: 1, pageSize: pageSize });
                    }}
                    withoutTable
                />
                <ChangePaymentMethodModal
                    show={showManualTransfer}
                    onClose={() => {
                        setShowManualTransfer(false);
                        setSelectOrderId(0);
                    }}
                    orderId={selectOrderId}
                    initTransfer={0}
                    onSave={() => {
                        setShowManualTransfer(false);
                        getDatas(paging);
                        setSelectOrderId(0);
                    }}
                />
                <QRModal
                    show={showQrModal}
                    onClose={() => {
                        setShowQrModal(false);
                        setSelectOrderId(0);
                        setShowFinnalyPrice(0);
                    }}
                    finallyPrice={showFinnalyPrice}
                    orderId={selectOrderId}
                />
            </section>
        </Wrapper>
    );
}

export default ManageReadyToShipList;

const Wrapper = styled.div`
    margin-top: 20px;

    .search-box {
        display: flex;
        height: 100%;
    }

    .search-area-filter {
        margin: 0 30px 0 auto;
        display: flex;
        align-items: center;
    }

    .search-box input[type="text"] {
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 0 12px;
        width: 300px;
    }

    .search-button.cursor-pointer {
        background: #64c5b1;
        display: flex;
        align-items: center;
        color: #fff;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        padding: 4px 8px;
    }

    section.action-list {
        display: flex;
    }

    .created-date-area {
        display: flex;
        align-items: center;
        margin-left: 12px;
    }

    .created-date-area label {
        margin: 0 10px 0 0px;
    }
    .transfer-action-item {
        width: 100%;
    }

    .transfer-action-item button {
        width: 100%;
    }

    span.edit-content.danger {
        color: #f96a74 !important;
    }

    .order-info {
        display: flex;
        align-items: center;
        margin: 10px 0;
    }

    .order-info span.edit-content {
        display: flex;
        align-items: center;
        flex: 1;
        color: #13aaff;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .order-info span.title {
        color: #a5a5a5;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 40%;
    }

    .status-info {
        margin: 10px 0;
    }

    .icon {
        display: flex;
    }

    span.status {
        color: #64c5b1;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    section.table-manage {
        margin-top: 20px;
    }

    img.thumbnail {
        width: 65px;
        height: auto;
        object-fit: contain;
    }

    table.table.table-bordered {
    }

    table.table.table-bordered thead {
        border: 1px solid #d9d9d9;
        background: #d9d9d9;
    }

    tbody {
        background: white;
    }

    .info-item {
        display: flex;
        margin: 5px 0;
        align-items: center;
    }

    .info-item span.title {
        width: 25%;
    }

    .info-item input.edit-content {
        flex: 1;
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 5px;
        margin-left: 5px;
    }

    .info-item textarea.edit-content {
        flex: 1;
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 5px;
        margin-left: 5px;
    }

    span.edit-content.link {
        color: #13aaff;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 5px;
    }

    .product-name {
        overflow: hidden;
        color: #444;
        text-overflow: ellipsis;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 6px 0;
    }

    .product-attr {
        margin: 6px 0;
        overflow: hidden;
        color: #a5a5a5;
        text-overflow: ellipsis;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .product-lst {
        display: flex;
        flex-wrap: wrap;
    }

    .product-lst span.badge.badge-custom {
        margin: 5px;
    }

    td.payment-inf {
    }

    td.payment-inf {
        vertical-align: middle;
    }

    .payment-info {
        margin: 5px 0;
        display: flex;
        align-items: center;
    }

    .payment-info span.title {
        width: 100px;
        color: #a5a5a5;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    .payment-info span.edit-content {
        flex: 1;
        color: #444;
        text-align: right;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    td.address-inf {
        vertical-align: middle;
    }

    .address-info span.title {
        width: 30%;
        color: #a5a5a5;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    .address-info {
        display: flex;
        margin: 10px 0;
    }

    .address-info span.edit-content {
        flex: 1;
        color: #444;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;
