import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Loading from "../based/Loading";
import Language from "./Language";
class CommonModal extends Component {
    constructor(props) {
        super(props);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleReject = this.handleReject.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
        this.handleSaveContinue = this.handleSaveContinue.bind(this);
        this.handleSyncData = this.handleSyncData.bind(this);
        this.handleSendFilter = this.handleSendFilter.bind(this);
        this.handleChangeOrderStatus = this.handleChangeOrderStatus.bind(this);
        this.handleTransferSuccessful = this.handleTransferSuccessful.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    handleConfirm() {
        this.props.onConfirm();
    }
    handleSave() {
        this.props.onSave();
    }
    handleRegister() {
        this.props.onRegister();
    }
    handleReject() {
        this.props.onReject();
    }
    handleSaveContinue() {
        this.props.onSaveContinue();
    }
    handleSyncData() {
        this.props.onSyncData();
    }
    handleSendFilter() {
        this.props.onSendFilter();
    }
    handleChangeOrderStatus() {
        this.props.onChangeOrderStatus();
    }
    handleTransferSuccessful() {
        this.props.onTransferSuccessful();
    }
    hide(e) {
        //Todo: nothing
    }

    escFunction(e) {
        if (e.keyCode === 27 && this.props.onClose) {
            this.props.onClose();
        }
    }
    render() {
        return (
            <Modal show={this.props.show} onHide={this.hide.bind(this)} size={this.props.size} id={this.props.id} enforceFocus={false} scrollable={true} style={{ maxHeight: "90vh", overflow: "auto" }}>
                <Loading show={this.props.isBusy ? this.props.isBusy : false} msg={this.props.busyMsg ? this.props.busyMsg : "Đang tải dữ liệu..."} />
                <Modal.Header>
                    <button type="button" className="close" onClick={this.props.onClose}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={this.props.classModalBody}>{this.props.children}</Modal.Body>
                {this.props.hiddenFooter ? null : (
                    <Modal.Footer>
                        {this.props.customButton ? this.props.customButton : null}
                        {this.props.onSave != undefined ? (
                            <Button variant="custom btn-sm" onClick={this.handleSave}>
                                <i className="fa fa-save"></i> {Language.getValue("common.btnSave")}
                            </Button>
                        ) : null}
                        {this.props.onSaveContinue != undefined ? (
                            <Button variant="outline-primary btn-sm" onClick={this.handleSaveContinue}>
                                <i className="fa fa-save"></i> {Language.getValue("common.btnSaveAndContinue")}
                            </Button>
                        ) : null}
                        {this.props.onSyncData != undefined ? (
                            <Button variant="custom btn-sm" onClick={this.handleSyncData}>
                                <i className="fa fa-download"></i> {Language.getValue("common.btnSyncData")}
                            </Button>
                        ) : null}
                        {this.props.onSendFilter != undefined ? (
                            <Button variant="custom btn-sm" onClick={this.handleSendFilter}>
                                <i className="fa fa-filter"></i> {Language.getValue("common.btnSendFilter")}
                            </Button>
                        ) : null}
                        {this.props.onChangeOrderStatus != undefined ? (
                            <Button variant="custom btn-sm" onClick={this.handleChangeOrderStatus}>
                                <i className="fa fa-save"></i> {Language.getValue("common.btnChangeOrderStatus")}
                            </Button>
                        ) : null}
                        {this.props.onConfirm != undefined ? (
                            <Button variant="custom btn-sm" onClick={this.handleConfirm}>
                                <i className="fa fa-o"></i> {Language.getValue("common.btnConfirm")}
                            </Button>
                        ) : null}
                        {this.props.onRegister != undefined ? (
                            <Button variant="custom btn-sm" onClick={this.handleRegister}>
                                <i className="fa fa-o"></i> {Language.getValue("common.signUp")}
                            </Button>
                        ) : null}
                        {this.props.onTransferSuccessful != undefined ? (
                            <Button variant="outline-primary btn-sm" onClick={this.handleTransferSuccessful}>
                                <i className="fa fa-o"></i> {Language.getValue("common.btnTransferSuccessful")}
                            </Button>
                        ) : null}
                        {this.props.onReject != undefined ? (
                            <Button variant="danger btn-sm" onClick={this.handleReject}>
                                <i className="fa fa-o"></i> {Language.getValue("common.btnReject")}
                            </Button>
                        ) : null}
                        {this.props.error ? (
                            <Button className="btn btn-danger w-md waves-light btn-sm" onClick={this.props.onClose}>
                                {Language.getValue("common.btnClose")}
                            </Button>
                        ) : this.props.onClose && !this.props.hideOnClose ? (
                            <Button className="btn btn-inverse waves-light btn-sm" onClick={this.props.onClose}>
                                {Language.getValue("common.btnCancel")}
                            </Button>
                        ) : null}
                    </Modal.Footer>
                )}
            </Modal>
        );
    }
}

CommonModal.propTypes = {
    show: PropTypes.bool,
};

export default CommonModal;
