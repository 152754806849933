import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CommonModal from "../based/refactor/CommonModal";
import Request from "../based/Request";
import OrderServices from "../based/services/OrderServices";
import { Notify } from "../based/Notify";
import { CONSTANTS, NOTIFY } from "../based/Constants";

function ChangePaymentMethodModal({ show, onClose, onSave, orderId, initTransfer }) {
    const [transferPartial, setTransferPartial] = useState(false);
    const [busy, setBusy] = useState(false);
    const [fileUrl, setFileUrl] = useState("");

    useEffect(() => {
        setTransferPartial(initTransfer);
    }, [initTransfer]);

    const handleSave = async (model) => {
        let [err, data] = await OrderServices.UpdateTranscriptStatus(model);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Cập nhật thành công");
            onSave();
        } else {
            let errMsg =
                err.errors && err.errors.length > 0
                    ? err.errors.reduce((prev, item, idx) => {
                          return `${prev}${item.value}<br/>`;
                      }, "")
                    : "" || CONSTANTS.MSG_ERROR;
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, errMsg);
        }
    };

    const handleChangeImage = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.readAsDataURL(file);

            reader.onloadend = () => {
                Request.UploadImage(file).then((res) => {
                    if (res && res.data) {
                        setFileUrl(res.data.imageUrl);
                    } else {
                        let errMsg =
                            res.errors && res.errors.length > 0
                                ? res.errors.reduce((prev, item, idx) => {
                                      return `${prev}${item.value}<br/>`;
                                  }, "")
                                : "" || CONSTANTS.MSG_ERROR;
                        Notify(NOTIFY.ERROR, NOTIFY.ERROR, errMsg);
                    }
                });
            };
        }
    };

    return (
        <CommonModal
            show={show}
            isBusy={busy}
            busyMsg={"Đang tải"}
            title={`Khách đã thanh toán`}
            onClose={() => onClose()}
            size="md"
            onSave={() => {
                handleSave({ orderId: orderId, transferPartial: transferPartial, transcriptUrl: fileUrl });
            }}
        >
            <Wrapper>
                <div className="form-group">
                    <label htmlFor="" className="form-label">
                        Số tiền khách hàng đã chuyển
                    </label>
                    <input
                        type="number"
                        value={transferPartial}
                        className="form-control"
                        onChange={(e) => {
                            setTransferPartial(+e.target.value);
                        }}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="" className="form-label">
                        Upload màn chụp chuyển khoản
                    </label>
                    <input
                        type="file"
                        className="form-control form-type"
                        placeholder="Chọn file"
                        onChange={(e) => {
                            handleChangeImage(e);
                        }}
                    />
                </div>
            </Wrapper>
        </CommonModal>
    );
}

export default ChangePaymentMethodModal;

const Wrapper = styled.div`
    input.form-control {
        border: 1px solid #ededed;
        background: #fff;
        border-radius: 0;
    }

    input.form-control.form-type {
        border: none;
        height: auto;
    }
`;
