import axios from "axios";
import React, { Component, Suspense, useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import ManageAction from "./actions/ManageAction";
import AdsManagement from "./ads-management/AdsManagement";
import AffiliateVerification from "./affiliate/affiliate-verification/AffiliateVerification";
import ConfigCommission from "./affiliate/config-commission/ConfigCommission";
import GroupAffiliator from "./affiliate/vip-affiliate/GroupAffiliator";
import SharingInteraction from "./affiliate/vip-affiliate/SharingInteraction";
import SharingRequirement from "./affiliate/vip-affiliate/SharingRequirement";
import VIPCampaign from "./affiliate/vip-affiliate/VIPCampaign";
import VIPRefunds from "./affiliate/vip-affiliate/VIPRefunds";
import AfterEffect from "./after-effect/AfterEffect";
import AfterEffectConfig from "./after-effect/AfterEffectConfig";
import AnyalyticManagement from "./analytic/AnyalyticManagement";
import KeywordManagement from "./analytic/KeywordManagement";
import PageCountManagement from "./analytic/PageCountManagement";
import ArtistDetail from "./artist/ArtistDetail";
import ArtistManagement from "./artist/ArtistManagement";
import ArtworksSetCensorship from "./artworks-censorship/ArtworksSetCensorship";
import AttributeManagement from "./attribute/AttributeManagement";
import ConfirmEmail from "./authen/ConfirmEmail";
import ForgetPasswod from "./authen/ForgetPasswod";
import Login from "./authen/Login";
import RefreshPassword from "./authen/RefreshPassword";
import Register from "./authen/Register";
import RoleManagement from "./authen/RoleManagement";
import UserManagement from "./authen/UserManagement";
import BankAccountManagement from "./bank-account/BankAccountManagement";
import BannerSetting from "./banner-setting/BannerSetting";
import BannerManagement from "./banner/BannerManagement";
import AccessDeniedPage from "./based/403Page";
import NotFoundPage from "./based/404Page";
import NotAcceptable from "./based/406Page";
import Common from "./based/Common";
import LoadingR from "./based/LoadingR";
import RouterConstants from "./based/RouteConstants";
import CommonServices from "./based/services/CommonServices";
import HotKeyServices from "./based/services/HotKeyServices";
import AddOrEditBlogPage from "./blog/AddOrEditBlogPage";
import BlogCategory from "./blog/BlogCategory";
import BlogKeywordManagement from "./blog/BlogKeywordManagement";
import BlogManagement from "./blog/BlogManagement";
import BlogPreview from "./blog/BlogPreview";
import ProcessLogoFile from "./brand/ProcessLogoFile";
import { default as BrandManage } from "./brand/refactor/Brand";
import CampaignBoosting from "./campaign-boostings/CampaignBoosting";
import CampaignBoostingMaster from "./campaign-boostings/CampaignBoostingMaster";
import CampaignBuyMore from "./campaign-buy-more/CampaignBuyMore";
import CampaignFreeShip from "./campaign-free-ship/CampaignFreeShip";
import CampaignMegaManagement from "./campaign-mega/CampaignMegaManagement";
import CampaignMilestoneManagement from "./campaign-milestone/refactor/CampaignMilestoneManagement";
import Category from "./category/Category";
import ChatUserManagement from "./chatuser-details/ChatUserManagement";
import PosterManage from "./collection-manage/PosterManage";
import PosterConfig from "./collection-manage/collection-config/PosterConfig";
import FontManage from "./collection-manage/font-config/FontManage";
import TemplateTypeManage from "./collection-manage/template-config/TemplateTypeManage";
import CollectionManagement from "./collection-show-homepage/CollectionManagement";
import ComboEdit from "./combo/ComboEdit";
import ComboManagement from "./combo/ComboManagement";
import ComingSoon from "./comming-soon-page/ComingSoon";
import { ApplicationContext, ApplicationInfoModel } from "./context/ApplicationContext";
import { LogisticContextProvider } from "./context/LogisticContext";
import Creator from "./creator/Creator";
import CreatorProfileRefactor from "./creator/CreatorProfileRefactor";
import TransferManagement from "./credit/TransferManagement";
import CustomizeCollectionType from "./customize-collection-type/CustomizeCollectionType";
import CustomizeCollection from "./customize-collection/CustomizeCollection";
import StockFont from "./customize-stock-font/StockFont";
import StockImage from "./customize-stock-image/StockImage";
import StockPhoto from "./customize-stock-photo/StockPhoto";
import CustomizeTag from "./customize-tag/CustomizeTag";
import { Home } from "./dashboard/Home";
import DirectSell from "./direct-sell/DirectSell";
import DownloadResourceConfig from "./download-resources/DownloadResourceConfig";
import EnhanceAccountManagement from "./enhance-account/EnhanceAccountManagement";
import FinanceMomo from "./finance-momo/FinanceMomo";
import FinanceOperator from "./finance-operator/FinanceOperator";
import OperatorAmountPayment from "./finance-operator/OperatorAmountPayment";
import FinancePrint from "./finance-print/FinancePrint";
import CodGHN from "./finance/CodGHN";
import CreatorLoyalty from "./finance/CreatorLoyalty";
import CreatorLoyaltyPayment from "./finance/CreatorLoyaltyPayment";
import FinanceManagement from "./finance/FinanceManagement";
import FlexiComboManagement from "./flexicombo/FlexiComboManagement";
import FolderProductBrandPrototype from "./folder-product-brand-prototype/FolderProductBrandPrototype";
import FolderProductPrototypeManagement from "./folder-product-prototype/FolderProductPrototype";
import PageAddProduct from "./folder-product-prototype/PageAddProduct";
import GameDetail from "./game/GameDetail";
import GamePage from "./game/GamePage";
import GiftManageTotal from "./gift-manage/GiftManageTotal";
import HandleOverToLotus from "./handle-over-to-lotus/HandleOverToLotus";
import AnswerContentManagement from "./help/AnswerContent/AnswerContentManagement";
import AddOrUpDateAnswerContent from "./help/AnswerContent/components/AddOrUpdateAnswerContent";
import AnswerContentPreview from "./help/AnswerContent/components/AnswerContentPreview";
import TypeQuestionManagement from "./help/TypeQuestion/TypeQuestionManagement";
import HotKeyManagement from "./hotkey-redirect/HotKeyManagement";
import InstructManage from "./instruct/InstructManage";
import { Layout } from "./layout/Layout";
import LevelConfig from "./level-config/LevelConfig";
import LogisticCreatePage from "./logistic/LogisticCreatePage";
import LogisticDetailPage from "./logistic/LogisticDetailPage";
import LogisticFilterPage from "./logistic/LogisticFilterPage";
import LogisticFilterScanPage from "./logistic/LogisticFilterScanPage";
import LogisticHistoryPage from "./logistic/LogisticHistoryPage";
import LogisticMainPage from "./logistic/LogisticMainPage";
import LogisticMultiCreatePage from "./logistic/LogisticMultiCreatePage";
import LogisticMultiDetailPage from "./logistic/LogisticMultiDetailPage";
import MainColorManagement from "./main-color/MainColorManagement";
import MTOCategoryManagement from "./make-to-order/MTOCategoryManagement";
import MangeGTXPrinter from "./manage-gtx-printer/MangeGTXPrinter";
import ManageOperatorOrder from "./manage-operator-order/ManageOperatorOrder";
import HandleOverToShipper from "./manage-order/HandleOverToShipper";
import ManageOrderTotal from "./manage-order/ManageOrderTotal";
import OrderDetail from "./manage-order/OrderDetail";
import ManageOrderAffiliate from "./manage-product-affiliate/ManageOrderAffiliate";
import ManageProductType from "./manage-product-type/ManageProductType";
import ProductVariantsEditor from "./manage-product-variants/ProductVariantsEditor";
import ManageProduct from "./manage-product/ManageProduct";
import ProductEditor from "./manage-product/ProductEditor";
import TopicManage from "./manage-topic/TopicManage";
import ManyShareManage from "./many-share/ManyShareManage";
import MarketingDetailManagement from "./marketing-landing/MarketingDetailManagement";
import MarketingLandingPage from "./marketing-landing/MarketingLandingPage";
import MarketingManagement from "./marketing-landing/MarketingManagement";
import ManageMenu from "./menus/ManageMenu";
import MessengerPhoto from "./messenger-photo/MessengerPhoto";
import MidjourneyPassReset from "./midjourney-pass/MidjourneyPassReset";
import OperatorOrderPacking from "./operator-order-packing/OperatorOrderPacking";
import OrderPacking from "./operator-order-packing/OrderPacking";
import { ShippingLabel } from "./operator-order-packing/ShippingLabel";
import PetManagement from "./pet-manage/PetManagement";
import PlatenSizeManage from "./platen-size/PlatenSizeManage";
import PresetConfig from "./preset/PresetConfig";
import PresetManage from "./preset/PresetManage";
import PrintTagPet from "./print-tag-pet/PrintTagPet";
import PrintRateCardSetting from "./print/PrintRateCardSetting";
import PrintSetting from "./print/PrintSetting";
import UVPrinterManagement from "./print/uv-printing/UVPrinterManagement";
import UVPrintingTableManagement from "./print/uv-printing/UVPrintingTableManagement";
import UVPrintingTableCal from "./print/uv-printing/components/UVPrintingTableCal";
import UVPrintingTableDetail from "./print/uv-printing/components/UVPrintingTableDetail";
import PrintingMainPage from "./printing/PrintingMainPage";
import ProcessOperatorFile from "./process-operator-files/ProcessOperatorFile";
import ProductCategoryManagement from "./product-category/ProductCategoryManagement";
import ProductDesign from "./product-design/ProductDesign";
import PushProduct from "./product-design/PushProduct";
import ProductPushManagement from "./product-push/ProductPushManagement";
import ProcessCover from "./production/ProcessCover";
import ProcessHandoverShipping from "./production/ProcessHandoverShipping";
import ProcessPressurePET from "./production/ProcessPressurePET";
import PersonalInfo from "./profile/PersonalInfo";
import BankAccountsRefactor from "./profile/refactor/BankAccountsRefactor";
import ChangePasswordRefactor from "./profile/refactor/ChangePassRefactor";
import UserReferencesRefactor from "./profile/refactor/UserReferencesRefactor";
import PushingFileTotal from "./pushing-file/PushingFileTotal";
import PushingManageDetail from "./pushing-file/PushingManageDetail";
import PushingManageMultiDetail from "./pushing-file/PushingManageMultiDetail";
import PushingManageTotal from "./pushing-file/PushingManageTotal";
import { store } from "./redux/store";
import AdminDash from "./refactor/dashboard/AdminDash";
import ReupManagement from "./reup/ReupManagement";
import ConnectPlatformConfig from "./sale-chanels/ConnectPlatformConfig";
import KeywordCollection from "./search/KeywordCollection";
import URLIndexing from "./seo/URLIndexing";
import SetProductManagement from "./set-product/SetProductManagement";
import ShelfManagement from "./shelf/ShelfManagement";
import SizeManagement from "./sizes/SizeManagement";
import SizeOptionManagement from "./sizes/SizeOptionManagement";
import SocialAccountManagement from "./social-accounts/SocialAccountManagement";
import StockFolderTotal from "./stock-folder/StockFolderTotal";
import StockSet from "./stock-set/StockSet";
import StockIn from "./stockmanager/StockIn";
import StockInHistory from "./stockmanager/StockInHistory";
import StockOut from "./stockmanager/StockOut";
import StockOutHistory from "./stockmanager/StockOutHistory";
import SummaryBanTraffic from "./summary-folder/SummaryBanTraffic";
import TagTrendingManage from "./tag-trending/template-config/TagTrendingManage";
import SizeStampManagement from "./tem-size/SizeStampManagement";
import TableRanus from "./template-manage/TemplateManage";
import Customer from "./third-party/Customer";
import CustomerAddressHistories from "./third-party/CustomerAddressHistories";
import Producer from "./third-party/Producer";
import Supplier from "./third-party/Supplier";
import TicketReturnOrder from "./ticket-return-order/TicketReturnOrder";
import TicketReturnOrderDetail from "./ticket-return-order/TicketReturnOrderDetail";
import Ticket from "./ticket/Ticket";
import AudioManagement from "./video/Audio/AudioManagement";
import FolderAudioManagement from "./video/FolderAudio/FolderAudioManagement";
import TagComboManagement from "./video/TagCombo/TagComboManagement";
import VoucherBrandTotal from "./voucher-brand/VoucherBrandTotal";
import VoucherCustomize from "./voucher-customize/VoucherCustomize";
import VoucherFreeship from "./voucher-freeship/VoucherFreeship";
import VoucherOverallEditor from "./voucher-overall/VoucherOverallEditor";
import VoucherOverallManagement from "./voucher-overall/VoucherOverallManagement";
import VoucherPromoteManagementAdmin from "./voucher-promotes/VoucherPromoteManagementAdmin";
import TargetGroup from "./voucher-target-group/TargetGroup";
import VoucherWinnerHistory from "./voucher-winner-history/VoucherWinnerHistory";
import InventoryManagement from "./warehouse/InventoryManagement";
import WarehouseManagement from "./warehouse/WarehouseManagement";
import Wareslot from "./warehouse/Wareslot";
import OPFWarehouseManagement from "./warehouse/seller/OPFWarehouseManagement";
import WebSetting from "./web-setting/WebSetting";
import VideoManagement from "./video-management/VideoManagement";
import PlatformShopManagement from "./platform-shop-management/PlatformShopManagement";
import PushingMultiDetail from "./pushing-file/PushingMultiDetail";
import ManageCrawl from "./crawl/ManageCrawl";
import ReadyToShipList from "./ready-to-shop-order/ReadyToShipList";
import ManageReadyToShipList from "./ready-to-shop-order/ManageReadyToShipList";

export default class App extends Component {
    displayName = App.name;
    constructor(props) {
        super(props);
        this.state = {
            applicationInfo: new ApplicationInfoModel(),
            location: window.location.href,
        };
        this.setApplicationInfo = this.setApplicationInfo.bind(this);
    }

    componentDidMount() {
        var token = Common.GetToken();
        if (window.location.href.indexOf(RouterConstants.ACCESS_DENIED) >= 0) return;
        if (token) {
            let _this = this;
            setTimeout(() => {
                _this.GetMenus();
            }, 1000);
        }
    }

    async GetMenus() {
        const [err, data] = await CommonServices.GetMenus();
        if (!err) {
            let appinfo = this.state.applicationInfo;
            appinfo.menus = data;
            this.setState({
                applicationInfo: appinfo,
            });
        }
    }

    setApplicationInfo(newData) {
        this.setState({ applicationInfo: newData });
    }

    render() {
        let obj = {
            applicationInfo: this.state.applicationInfo,
            setApplicationInfo: this.setApplicationInfo,
        };

        return (
            <ApplicationContext.Provider value={obj}>
                <React.StrictMode>
                    <Provider store={store}>
                        <Suspense fallback={<LoadingR fullScreen />}>
                            <BrowserRouter>
                                <LogisticContextProvider>
                                    <HotKey />
                                    <MenuAdmin />
                                </LogisticContextProvider>
                            </BrowserRouter>
                        </Suspense>
                    </Provider>
                </React.StrictMode>
            </ApplicationContext.Provider>
        );
    }
}

const HotKey = () => {
    const [hotKey, setHotKey] = useState([]);
    const history = useHistory();
    useEffect(() => {
        GetHotKeys();
    }, []);

    async function GetHotKeys() {
        const [err, data] = await HotKeyServices.GetHotKeyByPage({
            pageSize: 1000,
            pageNumber: 1,
        });
        if (!err && data) {
            setHotKey(data.listObjects);
        } else {
            setHotKey([]);
        }
    }

    const handleKeyDown = (event, data, pressedKeys) => {
        data.forEach((item) => {
            let arrayKey = item.hotKey.split("+");
            if (arrayKey.length === pressedKeys.length && arrayKey.length === 2) {
                if (arrayKey[0] === pressedKeys[0] && arrayKey[1] === pressedKeys[1]) {
                    history.push(`${item.redirectUrl}`);
                }
            }
            if (arrayKey.length === pressedKeys.length && arrayKey.length === 3) {
                if (arrayKey[0] === pressedKeys[0] && arrayKey[1] === pressedKeys[1] && arrayKey[2] === pressedKeys[2]) {
                    history.push(`${item.redirectUrl}`);
                }
            }
            if (arrayKey.length === pressedKeys.length && arrayKey.length === 4) {
                if (arrayKey[0] === pressedKeys[0] && arrayKey[1] === pressedKeys[1] && arrayKey[2] === pressedKeys[2] && arrayKey[3] === pressedKeys[3]) {
                    history.push(`${item.redirectUrl}`);
                }
            }
        });
    };

    useEffect(() => {
        const pressedKeys = [];
        const handleKeyDownWithParams = (event) => {
            if (event.key) {
                const key = event.key.toUpperCase();
                if (!pressedKeys.includes(key)) {
                    pressedKeys.push(key);
                }
                handleKeyDown(event, hotKey, pressedKeys);
            }
        };
        document.addEventListener("keyup", (event) => {
            if (event.key) {
                const key = event.key.toUpperCase();
                const index = pressedKeys.indexOf(key);
                if (index !== -1) {
                    pressedKeys.splice(index, 1);
                }
            }
        });
        document.addEventListener("keydown", handleKeyDownWithParams);
        return () => {
            document.removeEventListener("keydown", handleKeyDownWithParams);
        };
    }, [hotKey]);

    return <div></div>;
};

const MenuAdmin = () => {
    useEffect(() => {
        const handleError = (event) => {
            var content = `admin.ranus.vn [${new Date()}] - Location: ${window.location.href} - ${event.type}: ${event.message} - ${JSON.stringify(event)}}`;
            var ignoreError = ["Script error.", "zaloJSV2"];
            var ignoreFileName = ["Zalosdk.js"];
            if (process.env.NODE_ENV === "production" && !ignoreError.some((x) => event.message.includes(x)) && !ignoreFileName.some((x) => event.filename.includes(x)))
                axios.post(`https://api.ranus.vn/api/Home/hook-data-information`, { error: content }).then((res) => {});
        };
        window.addEventListener("error", handleError);
        return () => {
            window.removeEventListener("error", handleError);
        };
    }, []);

    return (
        <Switch>
            {/* Tài khoản */}
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Register} />
            <Route path="/confirm-email" component={ConfirmEmail} />
            <Route path="/refresh-password" component={RefreshPassword} />
            <Route path="/forgot-password" component={ForgetPasswod} />
            <Route exact path="/coming-soon" component={ComingSoon} />
            <Route exact path={RouterConstants.NOT_ACCEPTABLE} component={NotAcceptable} />
            <Route path={RouterConstants.ACCESS_DENIED} component={AccessDeniedPage} />
            <Route path="/shipping-label/:orderId" component={ShippingLabel} />
            <Route path="/summary-ban" component={SummaryBanTraffic} />

            {/* Logistic single */}
            <Route path="/logistic-history" component={LogisticHistoryPage} />
            <Route path="/logistic-create/:id?" component={LogisticCreatePage} />
            <Route path="/logistic-detail/:id?" component={LogisticDetailPage} />
            {/* Logistic multiple */}
            <Route path="/logistic" component={LogisticMainPage} />
            <Route path="/logistic-filter" component={LogisticFilterPage} />
            <Route path="/logistic-filter-scan" component={LogisticFilterScanPage} />
            <Route path="/logistic-multi-create" component={LogisticMultiCreatePage} />
            <Route path="/logistic-multi-detail/:sortingCode" component={LogisticMultiDetailPage} />

            {/* quản lý game */}
            <Route path="/game-management" component={GamePage} />
            <Route path="/game-detail/:gameId?" component={GameDetail} />

            <Layout>
                <Route exact path="/" component={Home} />
                <Route exact path="/home-refactor" component={AdminDash} />
                {/* Sản phẩm */}
                <Route path="/brand" component={BrandManage} />
                <Route path="/artworks-censorship" component={ArtworksSetCensorship} />
                <Route path="/manage-product" component={ManageProduct} />
                <Route path="/product-editor/:platform?/:productId" component={ProductEditor} />
                <Route path="/product-variants-editor/:platform/:productId/:action/:variantId?/" component={ProductVariantsEditor} />
                <Route path="/set-product" component={SetProductManagement} />
                <Route path="/manage-product-design" component={ProductDesign} />
                <Route path="/manage-product-push" component={ProductPushManagement} />
                <Route path="/push-product-design/:productId/:categoryId?" component={PushProduct} />
                <Route path="/direct-sell" component={DirectSell} />
                <Route path="/category" component={Category} />
                <Route path="/attribute-management" component={AttributeManagement} />
                <Route path="/manage-product-type" component={ManageProductType} />
                <Route path="/manage-topic" component={TopicManage} />
                <Route path="/manage-tag-trending" component={TagTrendingManage} />
                {/* Khuyến mãi */}
                <Route path="/voucher-overall-management" component={VoucherOverallManagement} />
                <Route path="/voucher-overall-editor/:voucherId?" component={VoucherOverallEditor} />
                <Route path="/voucher-freeship" component={VoucherFreeship} />
                <Route path="/voucher-brand" component={VoucherBrandTotal} />
                <Route path="/voucher-promotes" component={VoucherPromoteManagementAdmin} />
                <Route path="/voucher-winner-history" component={VoucherWinnerHistory} />
                <Route path="/campaign-milestone-management" component={CampaignMilestoneManagement} />
                <Route path="/campaign-boostings" component={CampaignBoosting} />
                <Route path="/campaign-boostings-master" component={CampaignBoostingMaster} />
                <Route path="/campaign-buy-more" component={CampaignBuyMore} />
                <Route path="/campaign-free-ship" component={CampaignFreeShip} />
                <Route path="/campaign-management" component={CampaignMegaManagement} />
                <Route path="/target-group" component={TargetGroup} />
                <Route path="/flexicombo-manage" component={FlexiComboManagement} />
                {/* Tài chính */}
                <Route path="/cod-ghn" component={CodGHN} />
                <Route path="/lotus-momo" component={FinanceMomo} />
                <Route path="/finance-printers" component={FinancePrint} />
                <Route path="/creator-loyalty" component={CreatorLoyalty} />
                <Route path="/creator-loyalty-payment" component={CreatorLoyaltyPayment} />
                <Route path="/finance-operators" component={FinanceOperator} />
                <Route path="/operators-amount-payment" component={OperatorAmountPayment} />
                <Route path="/finance" component={FinanceManagement} />
                {/* Quản lý tiếp thị liên kết */}
                <Route path="/affiliate-verification" component={AffiliateVerification} />
                <Route path="/affiliate-commission-configs" component={ConfigCommission} />
                <Route path="/affiliate-manage-order" component={ManageOrderAffiliate} />
                <Route path="/affiliate-sharing-requirements" component={SharingRequirement} />
                <Route path="/affiliate-vip/:thirdPartyId?" component={SharingInteraction} />
                <Route path="/affiliate-group-users" component={GroupAffiliator} />
                <Route path="/affiliate-vip-campaign" component={VIPCampaign} />
                <Route path="/affiliate-vip-refund" component={VIPRefunds} />
                {/* Thông tin cá nhân */}
                <Route path="/profile" component={Common.IsAdmin() ? PersonalInfo : CreatorProfileRefactor} />
                <Route path="/bank-accounts" component={BankAccountsRefactor} />
                <Route path="/security" component={ChangePasswordRefactor} />
                <Route path="/user-references" component={UserReferencesRefactor} />
                {/* Kho */}
                <Route path="/warehouse" component={WarehouseManagement} />
                <Route path="/warehouse-opf" component={OPFWarehouseManagement} />
                <Route path="/stock-in-history" component={StockInHistory} />
                <Route path="/stock-out-history" component={StockOutHistory} />
                <Route path="/inventory-manage" component={InventoryManagement} />
                <Route path="/stock-in/:warehouseId?/:slotId?" component={StockIn} />
                <Route path="/stock-out/:warehouseId?/:slotId?" component={StockOut} />
                <Route path="/wareslot/:warehouseId" component={Wareslot} />
                {/* Open Platform */}
                <Route path="/connect-platform-config/:platform?" component={ConnectPlatformConfig} />
                {/* Đơn hàng */}
                <Route path="/order" component={ManageOrderTotal} />
                <Route path="/ticket-return-order" component={TicketReturnOrder} />
                <Route path="/manage-credits" component={TransferManagement} />
                <Route path="/order-detail/:orderId" component={OrderDetail} />
                <Route path="/ticket-return-order-detail/:ticketId" component={TicketReturnOrderDetail} />
                <Route path="/handle-over-to-shipper" component={HandleOverToShipper} />
                {/* Sản xuất */}
                <Route path="/operator-order" component={ManageOperatorOrder} />
                <Route path="/process-cover" component={ProcessCover} />
                <Route path="/process-pressure" component={ProcessPressurePET} />
                <Route path="/process-operator-files" component={ProcessOperatorFile} />
                <Route path="/process-logo-files" component={ProcessLogoFile} />
                <Route path="/process-handover-shipping" component={ProcessHandoverShipping} />
                <Route path="/operator-order-packing" component={OperatorOrderPacking} />
                <Route path="/order-packing" component={OrderPacking} />
                <Route path="/handle-over-to-lotus" component={HandleOverToLotus} />
                <Route path="/print-tag-pet" component={PrintTagPet} />
                {/* In ấn */}
                <Route path="/print-config" component={PrintSetting} />
                <Route path="/print-rate-card" component={PrintRateCardSetting} />
                <Route path="/manage-gtx-config" component={MangeGTXPrinter} />
                <Route path="/uv-printer-management" component={UVPrinterManagement} />
                <Route path="/uv-printing-table-management" component={UVPrintingTableManagement} />
                <Route path="/uv-printing-table-detail/:detailId" component={UVPrintingTableDetail} />
                <Route path="/uv-printing-table-cal/:printingTableId" component={UVPrintingTableCal} />
                {/* Rút tiền */}
                <Route path="/manage-bank-accounts" component={BankAccountManagement} />
                {/* Người dùng */}
                <Route path="/supplier" component={Supplier} />
                <Route path="/creator" component={Creator} />
                <Route path="/producers" component={Producer} />
                <Route path="/customer" component={Customer} />
                <Route path="/customer-address-histories" component={CustomerAddressHistories} />
                {/* Hệ thống */}
                <Route path="/users-management" component={UserManagement} />
                <Route path="/roles-management" component={RoleManagement} />
                <Route path="/manage-menu" component={ManageMenu} />
                <Route path="/manage-action" component={ManageAction} />
                <Route path="/level-config" component={LevelConfig} />
                <Route path="/web-setting" component={WebSetting} />
                <Route path="/banner-setting" component={BannerSetting} />
                {/* Thống kê */}
                <Route path="/analytic-management" component={AnyalyticManagement} />
                <Route path="/keyword-management" component={KeywordManagement} />
                <Route path="/pagecount-management" component={PageCountManagement} />
                {/*Blog*/}
                <Route path="/blog-management" component={BlogManagement} />
                <Route path="/blog-category-management" component={BlogCategory} />
                <Route path="/blog-keyword-management" component={BlogKeywordManagement} />
                <Route path="/preview-blog/:id" component={BlogPreview} />
                <Route path="/blog/:id?" component={AddOrEditBlogPage} />
                {/*Ticket*/}
                <Route path="/ticket" component={Ticket} />
                {/* Thông tin và hỗ trợ */}
                <Route path="/download-resource-config" component={DownloadResourceConfig} />
                {/* make to order  */}
                <Route path="/mto-category-management" component={MTOCategoryManagement} />
                <Route path="/keyword-collection" component={KeywordCollection} />
                {/*Collection*/}
                <Route path="/PosterConfig/:id?" component={PosterConfig} />
                <Route path="/poster" component={PosterManage} />
                <Route path="/font-manage" component={FontManage} />
                <Route path="/template-type" component={TemplateTypeManage} />
                {/* SEO */}
                <Route path="/url-indexing-management" component={URLIndexing} />
                {/* Combo */}
                <Route path="/combo-management" component={ComboManagement} />
                <Route path="/combo-edit/:id?" component={ComboEdit} />
                {/* Tag */}
                <Route path="/tag-management" component={CustomizeTag} />
                <Route path="/collection-management" component={CustomizeCollection} />
                <Route path="/collection-type-management" component={CustomizeCollectionType} />
                <Route path="/stock-management" component={StockPhoto} />
                <Route path="/stock-image-management" component={StockImage} />
                <Route path="/stock-font-management" component={StockFont} />
                <Route path="/stock-folder" component={StockFolderTotal} />
                <Route path="/stock-set" component={StockSet} />
                {/** Help */}
                <Route path="/type-question-management" component={TypeQuestionManagement} />
                <Route path="/answer-content-management" component={AnswerContentManagement} />
                <Route path="/answer-content/:id?" component={AddOrUpDateAnswerContent} />
                <Route path="/preview-answer-content/:id" component={AnswerContentPreview} />
                {/** Video */}
                <Route path="/audio-management" component={AudioManagement} />
                <Route path="/folder-audio" component={FolderAudioManagement} />
                <Route path="/tag-combo" component={TagComboManagement} />
                <Route path="/after-effect-management" component={AfterEffect} />
                <Route path="/af-config/:id" component={AfterEffectConfig} />
                <Route path="/preset-management" component={PresetManage} />
                <Route path="/preset-config/:id?" component={PresetConfig} />
                {/** Social Account */}
                <Route path="/social-account" component={SocialAccountManagement} />
                <Route path="/artist-detail/:id?" component={ArtistDetail} />
                <Route path="/artist-management" component={ArtistManagement} />
                {/** User */}
                <Route path="/chatuser-management" component={ChatUserManagement} />
                <Route path="/enhance-account-management" component={EnhanceAccountManagement} />
                {/** Show Home Shop */}
                <Route path="/product-category-management" component={ProductCategoryManagement} />
                <Route path="/collection-homepage-management" component={CollectionManagement} />
                {/** Sản phẩm phôi tự thiết kế */}
                <Route path="/folder-product-prototype-management" component={FolderProductPrototypeManagement} />
                <Route path="/folder-product-brand-prototype-management" component={FolderProductBrandPrototype} />
                <Route path="/prototype-add/:appProductId" component={PageAddProduct} />
                {/**  Banner */}
                <Route path="/banner-management" component={BannerManagement} />
                {/**  Size */}
                <Route path="/size-option-management" component={SizeOptionManagement} />
                <Route path="/size-management" component={SizeManagement} />
                {/** Hotkey */}
                <Route path="/hotkey-management" component={HotKeyManagement} />
                {/** Hướng dẫn */}
                <Route path="/instruct-management" component={InstructManage} />
                {/* Reup */}
                <Route path="/reup-management" component={ReupManagement} />
                {/*Khay kệ */}
                <Route path="/sheft-management" component={ShelfManagement} />
                {/*Landing page marketing */}
                <Route path="/marketing-management" component={MarketingManagement} />
                <Route path="/marketing-landing-page/:creatorId" component={MarketingLandingPage} />
                <Route path="/marketing-detail/:creatorId" component={MarketingDetailManagement} />
                {/*ép PET */}
                <Route path="/pet-management" component={PetManagement} />
                <Route path="/voucher-customize" component={VoucherCustomize} />
                <Route path="/size-stamp" component={SizeStampManagement} />
                {/* Many share */}
                <Route path="/many-share" component={ManyShareManage} />
                {/* Mâu tự thiết kế */}
                <Route path="/template-management" component={TableRanus} />
                {/* Khung in DTG */}
                <Route path="/platen-size-management" component={PlatenSizeManage} />
                {/* Màu chủ đạo */}
                <Route path="/main-color-management" component={MainColorManagement} />
                {/* pass midjourney */}
                <Route path="/midjourney-pass-management" component={MidjourneyPassReset} />
                {/* messenger photo */}
                <Route path="/messenger-photo" component={MessengerPhoto} />
                {/* ads management */}
                <Route path="/ads-management" component={AdsManagement} />
                {/* push product */}
                <Route path="/pushing-file" component={PushingFileTotal} />
                <Route path="/pushing-manage" component={PushingManageTotal} />
                <Route path="/pushing-detail/:designId/:platform" component={PushingManageDetail} />
                <Route path="/pushing-detail-multi/:designId" component={PushingManageMultiDetail} />
                <Route path="/pushing-video-management" component={VideoManagement} />
                <Route path="/pushing-multi-detail/:designId" component={PushingMultiDetail} />
                {/* gift product */}
                <Route path="/gift-product-management" component={GiftManageTotal} />
                {/* quản lý pick hàng */}
                <Route path="/pick-batch-management/:printingBatch" component={PrintingMainPage} />
                {/* Chi tiết shop bán hàng */}
                <Route path="/platform-shop-management/:shopId?/:platform?" component={PlatformShopManagement} />
                {/* Quản lý sản phẩm được crawl */}
                <Route path="/crawl-manage" component={ManageCrawl} />
                {/* Quản lý tạo link đặt hàng trực tiếp */}
                <Route path="/ready-to-ship-list/:id?" component={ReadyToShipList} />
                <Route path="/manage-ready-to-ship-list" component={ManageReadyToShipList} />
            </Layout>

            {/* Common */}
            <Route component={NotFoundPage} />
        </Switch>
    );
};
