import React from "react";
import styled from "styled-components";
import { ARTWORK_TYPE } from "../based/Constants";
import CommonModal from "../based/Modal";

function ShowImageModal({ show, onClose, listImage }) {
    return (
        <CommonModal
            show={show}
            onClose={() => {
                onClose();
            }}
            title={"Ảnh sản phẩm"}
            size="lg"
        >
            <Wrapper>
                <div className="mockup__slider">
                    {listImage.map((item, i) => {
                        let img = item.productImageUrl ? item.productImageUrl.replace(".jpg", "_crop.webp") : "";
                        return (
                            <div key={i} className="image-mockup-slider">
                                <img src={img} alt="sub-image" />
                                <div className="type-name">{item.artworkType == ARTWORK_TYPE.Front ? "Mặt trước" : "Mặt sau"}</div>
                            </div>
                        );
                    })}
                </div>
            </Wrapper>
        </CommonModal>
    );
}

export default ShowImageModal;

const Wrapper = styled.div`
    .mockup__slider {
        display: flex;
    }

    .image-mockup-slider {
        width: 50%;
        height: 350px;
    }

    .image-mockup-slider img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .type-name {
        color: #444;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
    }
`;
