import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import { INITPAGING, NOTIFY, StockFolderType } from "../based/Constants";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import TemplateServices from "../based/services/TemplateServices";
import { initProductDesign } from "./ReadyToShipList";
import Search from "./assets/Search.svg";
import Dropdown from "./assets/Dropdown.svg";
import CustomizeServices from "../based/services/CustomizeServices";

function ReadyToShipProductModal({ show, onClose, onSelect, listSelectedDesignId }) {
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState({ ...INITPAGING, orderDirection: "desc", stockFolderId: 0 });
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [listFolder, setListFolder] = useState([]);
    const observer = useRef(null);

    useEffect(() => {
        getDatas(paging);
        getLevel1Folder();
    }, []);

    const getDatas = async (page) => {
        setLoading(true);
        const [err, data] = await TemplateServices.GetTemplate(page);
        if (!err && data && data.listObjects && data.listObjects.length > 0) {
            if (page.pageNumber == 1) setDatas(data.listObjects);
            else setDatas([...datas, ...data.listObjects]);
            setPaging(data.paging);
            setHasMore(true);
        } else {
            if (page.pageNumber == 1) setDatas([]);
            setHasMore(false);
            setPaging(page);
        }
        setLoading(false);
    };

    const setLastCollectionHandler = (element) => {
        if (loading || !hasMore) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                let updatePaging = { ...paging };
                updatePaging.pageNumber += 1;
                getDatas(updatePaging);
            }
        });
        if (element) observer.current.observe(element);
    };

    const handleClose = () => {
        let dt = [...datas];
        dt.forEach((item) => {
            item.active = false;
        });
        onClose();
    };

    const handleSelect = () => {
        let selected = datas.filter((item) => item.active);
        if (selected.length == 0) {
            Notify(NOTIFY.INFO, NOTIFY.INFO, "Vui lòng chọn sản phẩm để thêm vào đơn hàng");
            return;
        } else {
            let newLst = selected.map((item) => {
                let md = { ...initProductDesign };
                md.id = item.id;
                md.title = item.title;
                md.templateImages = item.templateImages;
                md.price = item.price;
                md.salePrice = item.salePrice;
                md.imageUrl = item.imageUrl;
                md.packageType = item.packageType;
                md.printType = item.printType;
                md.sAppProductId = item.appProductId;
                md.sColorCode = item.colorCode;
                md.merchantLink = item.merchantLink;
                return md;
            });
            onSelect(newLst);

            //reset lại list active
            let dt = [...datas];
            dt.forEach((item) => {
                item.active = false;
            });
            setDatas(dt);
        }
    };

    const getLevel1Folder = async () => {
        const [err, data] = await CustomizeServices.GetCustomizeFolders({ ...INITPAGING, pageNumber: 1, pageSize: 100, stockFolderType: StockFolderType.PhotoTemplate });
        if (!err && data) {
            setListFolder(data.listObjects);
        } else {
            setListFolder([]);
        }
    };

    const getLevel2Folder = async (id) => {
        const [err, data] = await CustomizeServices.GetCustomizeFolders({ ...INITPAGING, pageNumber: 1, pageSize: 100, stockFolderType: StockFolderType.PhotoTemplate, folderId: id });
        if (!err && data) {
            let lst = [...listFolder];
            lst.forEach((item) => {
                if (item.id == id) {
                    item.child = data.listObjects;
                    item.active = true;
                }
            });
            setListFolder(lst);
        }
    };

    const selectCount = useMemo(() => {
        return datas ? datas.filter((item) => item.active).length : 0;
    }, [datas]);

    return (
        <CommonModal
            show={show}
            onClose={handleClose}
            title="Thêm sản phẩm"
            size="lg"
            hideOnClose
            customButton={
                <React.Fragment>
                    <div className="selected-count">Đã chọn {selectCount} sản phẩm</div>
                    <button
                        className="btn btn-danger"
                        onClick={(e) => {
                            handleClose();
                        }}
                    >
                        Đóng
                    </button>
                    <button
                        className="btn btn-custom"
                        onClick={(e) => {
                            handleSelect();
                        }}
                    >
                        Chọn
                    </button>
                </React.Fragment>
            }
        >
            <Wrapper>
                <section className="filter-button">
                    <div className="search-area">
                        <input
                            type="text"
                            placeholder="Tìm kiếm"
                            value={paging.keyword}
                            onChange={(e) => {
                                setPaging({ ...paging, keyword: e.target.value });
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    getDatas({ ...paging, pageNumber: 1, stockFolderId: 0 });
                                }
                            }}
                        />
                        <div
                            className="btn-search"
                            onClick={(e) => {
                                getDatas({ ...paging, pageNumber: 1, stockFolderId: 0 });
                            }}
                        >
                            Tìm kiếm
                        </div>
                    </div>
                    {/* <div className="filter-product">
                        <div className="product-check-box">
                            <div className="check-box-item">
                                <input type="checkbox" />
                                <label>Áo thun</label>
                            </div>
                            <div className="check-box-item">
                                <input type="checkbox" />
                                <label>Bình nước</label>
                            </div>
                            <div className="check-box-item">
                                <input type="checkbox" />
                                <label>Túi tote</label>
                            </div>
                            <div className="check-box-item">
                                <input type="checkbox" />
                                <label>Ốp lưng</label>
                            </div>
                        </div>
                        <div className="color-check-box">
                            <div className="check-box-item">
                                <input type="checkbox" />
                                <label>White Rice</label>
                            </div>
                            <div className="check-box-item">
                                <input type="checkbox" />
                                <label>Black</label>
                            </div>
                            <div className="check-box-item">
                                <input type="checkbox" />
                                <label>Red</label>
                            </div>
                            <div className="check-box-item">
                                <input type="checkbox" />
                                <label>Be</label>
                            </div>
                        </div>
                    </div> */}
                </section>
                <section className="filter-bst">
                    <div className="search-bst">
                        <input type="text" placeholder="Tìm kiếm" />
                        <div className="search-icon">
                            <img src={Search} alt="" />
                        </div>
                    </div>
                    <div className="simplebar-content">
                        <ul className="side-nav">
                            {listFolder &&
                                listFolder.map((item, idx) => {
                                    return (
                                        <li className="side-nav-item" key={idx}>
                                            <a
                                                href=""
                                                className="side-nav-link"
                                                onClick={(e) => {
                                                    e.preventDefault();

                                                    //nếu đã có child thì không cần gọi api
                                                    if (item.child) {
                                                        let lst = [...listFolder];
                                                        let ele = lst.find((x) => x.id == item.id);
                                                        ele.active = !ele.active;
                                                        setListFolder(lst);
                                                    } else {
                                                        getLevel2Folder(item.id);
                                                    }
                                                }}
                                            >
                                                <span className="menu-icon"></span>
                                                <span className="menu-text">{item.name}</span>
                                                <span className="menu-arrow">
                                                    <div className="icon">
                                                        <img src={Dropdown} alt="" />
                                                    </div>
                                                </span>
                                            </a>
                                            <div className={`collase ${item.active ? "show" : ""}`}>
                                                <ul className="sub-menu">
                                                    {item.child &&
                                                        item.child.map((subItem, subIdx) => {
                                                            return (
                                                                <li className={`side-nav-item ${subItem.id == paging.stockFolderId ? "active" : ""}`} key={subIdx}>
                                                                    <a
                                                                        className="side-nav-link"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            if (subItem.id != paging.stockFolderId) {
                                                                                getDatas({ ...paging, pageNumber: 1, stockFolderId: subItem.id });
                                                                            } else {
                                                                                getDatas({ ...paging, pageNumber: 1, stockFolderId: 0 });
                                                                            }
                                                                        }}
                                                                    >
                                                                        <span className="menu-text">{subItem.name}</span>
                                                                    </a>
                                                                </li>
                                                            );
                                                        })}
                                                </ul>
                                            </div>
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                </section>
                <section className="list-product">
                    {datas && datas.length > 0 ? (
                        datas.map((item, index) => {
                            let imageUrl = item.imageUrl;
                            if (imageUrl) {
                                imageUrl = imageUrl.replace(".jpg", "_crop.webp");
                            }

                            let alreadySelectCount = listSelectedDesignId ? listSelectedDesignId.filter((x) => x == item.id).length : 0;
                            return (
                                <div
                                    className={`product-item ${item.active ? "active" : ""} ${alreadySelectCount > 0 ? "selected" : ""}`}
                                    key={index}
                                    ref={setLastCollectionHandler}
                                    onClick={(e) => {
                                        let dt = [...datas];
                                        dt[index].active = !dt[index].active;
                                        setDatas(dt);
                                    }}
                                >
                                    <div className="avatar">
                                        <img src={imageUrl} alt="" />
                                        <div className="location-product">
                                            <span>Tự thiết kế</span>
                                        </div>
                                        {item.artworkCount == 2 && (
                                            <div className="count-print-side">
                                                <span>2 mặt</span>
                                            </div>
                                        )}
                                        <div className="checkbox-item">
                                            <input type="checkbox" checked={item.active} onChange={(e) => {}} />
                                        </div>
                                        <div className="already-selected">{alreadySelectCount > 0 && <span>Đã chọn {alreadySelectCount}</span>}</div>
                                    </div>
                                    <div className="product-name">
                                        <span>{item.title}</span>
                                    </div>
                                    <div className="product-price">
                                        <span className="sale-price">{Common.formatCurrency(item.salePrice)}</span>
                                        <span className="sell-price">{Common.formatCurrency(item.price)}</span>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div colSpan={1000} className="center">
                            Không có dữ liệu
                        </div>
                    )}
                </section>
            </Wrapper>
        </CommonModal>
    );
}

export default ReadyToShipProductModal;

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    .already-selected {
        position: absolute;
        right: 5px;
        top: 5px;
        display: flex;
    }

    .already-selected span {
        border-radius: 40px;
        background: #febd6a;
        backdrop-filter: blur(2px);
        padding: 1px 10px;
        color: #444;
        text-align: right;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 166.667% */
    }

    .product-item.selected {
        background: #febd6a;
    }

    .checkbox-item {
        position: absolute;
        left: 5px;
        top: 5px;
    }

    .product-item {
        position: relative;
    }

    input[type="checkbox"] {
        width: 20px;
        height: 20px;
        appearance: none; /* Remove default styling */
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        cursor: pointer;
        position: relative;
        background: #fff;
    }

    input[type="checkbox"]:checked {
        background-color: #64c5b1; /* Change background to green */
        border-color: #64c5b1;
    }

    input[type="checkbox"]::before {
        content: "✔"; /* Checkmark */
        font-size: 16px;
        color: white;
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    input[type="checkbox"]:checked::before {
        display: block;
    }

    li.side-nav-item.active {
        background: #c7faf0;
    }
    span.menu-arrow {
        margin-left: auto;
    }

    section.list-product {
        align-self: baseline;
    }

    .search-bst {
        padding: 4px;
    }

    section.filter-button {
        width: 100%;
    }

    section.filter-bst {
        width: 20%;
        border: 1px solid #d9d9d9;
        background: #fff;
    }

    section.list-product {
        flex: 1;
    }

    .search-bst {
        display: flex;
        align-items: center;
        /* height: 48px; */
        margin: 8px;
        border: 1px solid #f3f3f3;
    }

    .search-bst input[type="text"] {
        height: 100%;
        border: none;
        /* padding-left: 6px; */
        flex: 1;
    }

    .search-bst div.btn-search {
        border: none;
        height: 100%;
    }

    .simplebar-content {
        margin: 8px;
        background: #fff;
    }

    ul.side-nav {
        padding-left: 0px;
        list-style: none;
    }

    .collase {
        display: none;
    }

    .collase.show {
        display: block;
    }

    ul.sub-menu {
        list-style: none;
        padding: 0;
    }

    li.side-nav-item {
        border: 0.5px solid #d9d9d9;
        background: #fff;
        /* border-left: 0; */
    }

    a.side-nav-link {
        width: 100%;
        display: flex;
        padding: 8px 4px 8px 12px;
    }

    .sub-menu a.side-nav-link {
        width: 100%;
        display: flex;
        padding: 8px 4px 8px 30px;
    }

    a.side-nav-link {
    }

    a.side-nav-link {
        color: #676767;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .sub-menu li.side-nav-item {
        border-left: 0;
        border-right: 0;
    }
    section.filter-button {
        display: flex;
        margin: 10px 0;
    }

    .filter-product {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 20px;
    }

    .filter-product {
    }

    .product-check-box {
        display: flex;
        align-items: center;
        margin: 0 0 10px 0;
    }

    .check-box-item {
        margin: 0 10px;
        display: flex;
        align-items: center;
    }

    .check-box-item label {
        margin: 0 0 0 5px;
    }

    .color-check-box {
        display: flex;
        align-items: center;
    }

    .search-area {
        height: 46px;
        border: 1px solid #d9d9d9;
    }

    .search-area {
        display: flex;
        align-items: center;
    }

    .btn-search {
        display: flex;
        align-items: center;
        padding: 4px;
    }

    .search-area input[type="text"] {
        height: 100%;
        background: #fff;
        border: none;
        padding: 4px 8px;
    }

    .search-area div.btn-search {
        height: 100%;
        border: none;
        background: #64c5b1;
        color: #fff;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
    }

    section.list-product {
        display: flex;
        flex-wrap: wrap;
    }

    .product-item {
        width: 160px;
        margin: 10px;
        border: 1px solid #d9d9d9;
        background: #fff;
        display: flex;
        flex-direction: column;
        padding: 5px;
    }

    .product-item.active {
        border: 1px solid #d9d9d9;
        background: #c7faf0;
    }

    .avatar {
        width: 100%;
        position: relative;
        margin: auto 0;
    }

    .product-item img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    .location-product {
        position: absolute;
        bottom: 5px;
        left: 5px;
        border-radius: 40px;
        background: #f20e45;
        padding: 2px 4px;
    }

    .location-product span {
        color: #fff;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
    }

    .count-print-side {
        position: absolute;
        right: 5px;
        bottom: 5px;
        border-radius: 40px;
        background: rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(2px);
        color: #fff;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
        padding: 2px 4px;
    }

    .product-name {
        color: #444;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        padding: 5px 2px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    .product-price {
        padding: 5px 1px;
    }

    span.sale-price {
        color: #f96a74;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }

    span.sell-price {
        color: #a4a4a4;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        text-decoration: line-through;
        margin-left: 5px;
    }
`;
